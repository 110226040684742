<template>

 <div  class="body" style="position: relative;">
  <div  class="new_header"> 
    <div   style="width: 100%;margin: auto;">
     <div v-if="selectedFruits.length==0" style="height: 55rem;  display: flex;justify-content: space-between;align-items: center;margin: 0 16rem;">
        <div @click="openBottomSheet(1)">
          <img src="@/assets/wap/more.svg" alt="logo" style="width:24rem;height:25rem;">&nbsp;
          
        </div>

        <div style="position: relative; width: 65%;display: flex;align-items: center;">
              <a-input  class="custom-input"
                        style="padding-left: 15rem;"
                        size="large" 
                        v-model="fileName" 
                        :placeholder="ll[languageId].search_all" 
                        @keyup.enter="goto()" 
                        @focus="handleFocus"
                        @blur="handleBlur"
                      >  
              </a-input>
              <div v-if="fileName" style="position: absolute;right:10rem;cursor: pointer;display: flex;align-items: center;" @click="clear()">
                <img  src="@/assets/wap/clear.png" alt="logo" style="width:16rem;height:16rem;">
              </div>
        </div>
        <div  style="position: relative;cursor: pointer;display: flex;align-items: center;" @click="openBottomSheet(5)">
          <img  src="@/assets/wap/send_list.svg" alt="logo" style="width:25rem;height:25rem;">
          <div v-if="Object.keys(this.uploadList).length>0" style="position:absolute;top:-7rem;right: -4rem;font-size:8rem;color:#fff;background-color: #F46160;border-radius: 7rem;padding:0rem 3rem;">{{ Object.keys(this.uploadList).length }}</div>
        </div>
        
     </div>

     <div v-if="selectedFruits.length>0" style="height: 55rem;  display: flex;justify-content: space-between;align-items: center;    background: #000;color: #fff;padding: 0 16rem;">
        <div style="" @click="selectedFruits=[]">
          <!-- <img  src="@/assets/wap/close.png" alt="logo" style="width:24rem;height:24rem;"> -->
          {{ll[languageId].cancel}}
        </div>

        <div v-if="selectedFruits.length>0" style="color: #fff;font-size: 15rem;font-weight: bold;">{{ll[languageId].selected}}{{selectedFruits.length}}{{ll[languageId].selected_file}}</div>

        <div @click="selectAll" style="font-size: 15rem;font-weight: bold;color:#fff;">
          <div  v-if="selectedFruits.length>0 && selectedFruits.length<resData.length">
            {{ll[languageId].selecte_all}}
          </div>
          <div  v-if="selectedFruits.length==resData.length">
            {{ll[languageId].unselecte_all}}
          </div>
        </div>
     </div>
      
     
     
     <div class="scroll-row">
      <div class="scroll-content">
        <div class="scroll-item" v-for="item in fileTypeList" :key="item.type" :class="item.dir == fileTypeListId ? 'left-item-on' : ''" @click="changeId(item);">
          <div style="">
                  <div v-if="item.dir=='/paper/'">{{ll[languageId].paper_name}}</div>
                  <div v-if="item.dir=='/meeting/'">{{ll[languageId].meeting_name}}</div>
                  <div v-if="item.dir=='/picking/'">{{ll[languageId].picking_name}}</div>
                  <div v-if="item.dir=='/learning/'">{{ll[languageId].learning_name}}</div>
                  <div v-if="item.dir=='/memo/'">{{ll[languageId].memo_name}}</div>
                  <div v-if="item.dir=='/picture/'">{{ll[languageId].picture_name}}</div>
                  <div v-if="item.dir=='/daily/'">{{ll[languageId].daily_name}}</div>
                  <div v-if="item.dir=='/other/'">{{ll[languageId].other_name}}</div>
              </div> 
          <div v-if="item.dir == fileTypeListId" class="left-item-on-line"></div> 
        </div>
      </div>
    </div>
  </div>

  </div>

  <div class="c-right">
    <div style="display: none;position: fixed;top:88rem;left:200rem;    width: calc(100% - 200rem);z-index: 1000;background-color: #fff;
  padding: 25rem;" class="path">
        <div style="display: flex;justify-content: flex-start;align-items: center;">
          <!-- <div>笔记</div> -->
          <div style="display: flex;justify-content: flex-start;">
            <div v-if="preDir" style="color:#000;font-size: 16rem;font-weight: 600;cursor: pointer;" class="border_right" @click="goPreDir()">{{ll[languageId].return}}</div>
            <div style="color:#000;font-size: 16rem;font-weight: 600;" :style="preDir?'margin-left:15rem':''">
              <span>{{dir.replace(/^\/|\/$/g, '')}}</span> <span style="margin-left: 5rem;">({{ resData.length}})</span></div>
          </div>

      

          <div style="margin-left: 25rem;position: relative; ">
          <a-input  class="custom-input"
                    size="large" 
                    v-model="fileName" 
                    :placeholder="ll[languageId].search_all" 
                    @keyup.enter="goto()" 
                    @focus="handleFocus"
                    @blur="handleBlur"
                  >  
          </a-input>
          <div style="position: absolute;top:8rem;right:10rem;cursor: pointer;" @click="goto()">
            <img  src="@/assets/wap/search.png" alt="logo" style="width:17rem;height:17rem;">
          </div>
        </div>
        </div>

        <div style="display: flex;align-items: center;justify-content: flex-end;">

        <div v-if="selectedFruits.length>0" style="color: #000;font-size: 14rem;">{{ll[languageId].selected}}{{selectedFruits.length}}{{ll[languageId].selected_file}}</div>
        <div style="margin-left: 20rem; display: flex;cursor: pointer;">
          <div style="display: flex;justify-content: flex-start;background-color: #000;border-radius: 2rem;align-items: center;">
            <div @click="handleDownload('')" style="color:#fff;font-size: 13rem;font-weight: 600;height: 36rem;padding-left:20rem;padding-right: 20rem;display: flex;align-items: center;justify-content: center ;" class="border_right_d">{{ll[languageId].download}}</div>
            <div style="color: #fff;font-size: 12rem;margin-top:-3rem;">|</div>
            <div @click="openRemoveModal('')" style="color:#fff;font-size: 13rem;font-weight: 600;height: 36rem;padding-left:20rem;padding-right: 20rem;display: flex;align-items: center;justify-content: center ;">{{ll[languageId].delete}}</div>
          </div>
          <div v-if="selectedFruits.length>0" style="color:#fff;font-size: 13rem;margin-left: 15rem;width: 67rem;display: flex;font-weight: 600;align-items: center;justify-content: center ;background-color: #000;border-radius: 2rem;" @click="showDeviceListModal()">{{ll[languageId].send}}</div>
        </div>
        <div  style="margin-left: 30rem;margin-right: 10rem;cursor: pointer;" @click="goto(0)">
          <img  src="@/assets/wap/refresh.png" alt="logo" style="width:25rem;height:25rem;">
          </div>
          <div v-if="fileTypeListId != '/picking/' && fileTypeListId !='/memo/'" @click="handleUploadType1" style="cursor: pointer;width: 108rem;height: 42rem;background: #275B52;border-radius: 2rem;display: flex;align-items: center;justify-content: center;margin-left: 20rem;">
            <img  src="@/assets/wap/import.png" alt="logo" style="width:21rem;height:21rem;">
            <div style="color:#fff;font-size: 16rrem;">{{ll[languageId].import}}</div>
          </div>

          <a-upload 
        ref="upload"
        multiple 
        :directory="uploadDirectory"
        :openFileDialogOnClick="uploadFileOpen"
        :showUploadList="false"
        :beforeUpload="handleBeforeUpload"
        :accept="fileTypesString"
        ></a-upload>


        </div>
    </div>

    <div style="margin-bottom: 200rem;">
      <div  style="z-index: 1000;width: calc(100% - 250rem);padding: 20rem 0 20rem 25rem; margin-left:25rem;margin-right:25rem;position: fixed; top:156rem;left:200rem;background: #F2F4F0;height: 48rem;display: flex;
      align-items: center;justify-content: flex-start;font-size: 15rem;color:#000;font-weight: 600;display:none;">
        <div style="width:5%;cursor: pointer" @click="selectAll" >
          <div v-if="selectedFruits.length>0">
            <img  src="@/assets/wap/selected.png" alt="logo" style="width:16rem;height:16rem;">
          </div>
          <div v-if="selectedFruits.length==0">
            <img  src="@/assets/wap/unselect.png" alt="logo" style="width:16rem;height:16rem;">
          </div>
        </div>
        <div style="width:35%;">{{ll[languageId].name}}</div>
        <div style="width:15%;">{{ll[languageId].type}}</div>
        <div style="width:15%;">{{ll[languageId].size}}</div>
        <div style="width:15%;">{{ll[languageId].time}}</div>
        <div style="width:15%;text-align: center;">{{ll[languageId].operation}}</div>
      </div>
        
      <a-spin :spinning="loading" class="loading-spinner">
          <div  class="content_content" style="margin-top:110rem;font-size: 14rem;color:#3A3A3A;font-weight: 600;display: flex;flex-direction: column;align-items: center;padding-bottom: 100rem;" >
              <div style="padding-top: 16rem;padding-bottom: 16rem;cursor: pointer;width: 100%;display: flex;
              align-items: center;justify-content: flex-start;color: #3A3A3A;font-size: 14rem;    padding-left: 16rem;padding-right: 16rem;"
              v-for="(item) in resData" :key="item.key" :style="isSelected(item.id) ? 'background: #F9FAF9;':'background: #fff;'" @click="updateSelection(item.id)">
                

              <div style="flex:1;display: flex;overflow: hidden;">
                <div style=" display: flex;align-items: center;" >
                    <div v-if="item.type==1" style="border: 0.4rem solid #b8b5b5;border-radius: 4rem;overflow: hidden;"><img  src="@/assets/wap/folder-ic.png" alt="logo" style="width:42rem;height:57rem;cursor: pointer;" @click.stop="goNextDir(item)"></div>
                    
                    <div v-if="item.type==2 && isImageFile(item.path)" style="border: 0.4rem solid #b8b5b5;border-radius: 4rem;overflow: hidden;width: 42rem;;">
                      <img  :src="item.path" alt="logo" style="width:42rem;height:57rem;"></div>
                    <div v-if="item.type==2 && !isImageFile(item.path)" style="border: 0.4rem solid #b8b5b5;border-radius: 4rem;overflow: hidden;width: 42rem;">
                      <img  :src="getImageSrc(item.path)" alt="logo" style="width:42rem;height:57rem;"></div>
                    
                    

                </div>

                <div style="display: flex;margin-left: 18rem;flex-direction: column;margin-top:4rem;margin-bottom: 4rem;justify-content: space-between;overflow: hidden;"> 

                  <div style="font-size: 14rem;font-weight: bold;">
                    <div v-if="item.type==1" style="cursor: pointer;font-weight: bold;flex:1;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;" @click="goNextDir(item)">{{removePrefix(item.name,dir)}}</div>
                    <div v-if="item.type!=1" style="font-weight: bold;  flex:1;  text-overflow: ellipsis;white-space: nowrap;overflow: hidden;padding-right: 20rem;">{{item.name}}</div>
                  </div>
                  


                  <div  style="display: flex;justify-content: flex-start;align-items: center;color:#979BA2;font-size: 12rem;font-weight: 400;">
                    <div style="margin-right: 15rem;">{{ item.size }}</div>
                    <div >|</div>
                    <div style="margin-left:15rem;">{{ formatDate(item.updateAt) }}</div>
                  </div>


                </div>
                
            
              </div>

              
                <div style="width: 22rem;height:23rem;cursor: pointer">
                  <div v-if="isSelected(item.id)" style="" >
                  <img  src="@/assets/wap/selected.png" alt="logo" style="width:22rem;height:23rem;">
                </div>
                <div v-else style="" >
                  <img  src="@/assets/wap/unselect.png" alt="logo" style="width:22rem;height:23rem;">
                </div>
                </div>
              </div>

              <div v-if="resData.length==0 && loading==false" style="margin-top: 150rem;align-items: center;display: flex;flex-direction: column;justify-content: center;">
                  <div style="width:101rem;height: 101rem;"><img  src="@/assets/wap/noList.png" alt="logo" style="width:101rem;"></div>
                <div style="font-size: 12rem;font-weight: 500;margin-top:15rem;">{{ll[languageId].no_data}}</div>
              </div>
        </div>
      </a-spin>
    </div>
  
    <a-table  style="display: none"
    :columns="columns" 
    :data-source="resData.items"
    :pagination="false"
    :rowKey="(record,index) => index"
    :row-selection="{selectedRowKeys:selectedRowKeys,onChange:onSelectChange}"
    :customRow="customRowFunc"
    >
      <template slot="name" slot-scope="text, record,index">
        <a-icon type="folder" v-if="record.isDir"/>
        <a-icon type="file" v-else/>
        &nbsp;&nbsp;
        <template v-if="addFolder && index === 0">
            <a-input v-model="addFolderName" ref="addFolderInputRef" style="width:50%" @keyup.enter="handleAddFolderOK"/>&nbsp;
            <a-button icon="check" type="primary" size="small" @click="handleAddFolderOK"/>&nbsp;
            <a-button icon="close" type="primary" size="small" @click="handleAddFolderCancle"/>
        </template>
        <template v-else-if="renameIndex === index">
            <a-input v-model="renameValue" ref="renameInputRef" style="width:50%" @keyup.enter="handleRenameOK"/>&nbsp;
            <a-button icon="check" type="primary" size="small" @click="handleRenameOK"/>&nbsp;
            <a-button icon="close" type="primary" size="small" @click="handleRenameCancle"/>
        </template>
        <template v-else>
          <a v-if="record.isDir" href="javascript:;" @click="gonext(text)">{{text}}</a>
          <span v-else>{{text}}</span>
          </template>
      </template>
    </a-table>
  </div>

  <div @click="handleUploadType1" v-if="selectedFruits.length==0 && ( dir=='/meeting/' || dir=='/learning/')" style="position: fixed;right: 16rem;bottom:110rem;">
    <img src="@/assets/wap/add.png" alt="logo" style="width:53rem;height:53rem;">&nbsp;
  </div>
  <div @click="handleUploadType2" v-if="selectedFruits.length==0 && (dir=='/paper/')" style="position: fixed;right: 16rem;bottom:110rem;">
    <img src="@/assets/wap/add.png" alt="logo" style="width:53rem;height:53rem;">&nbsp;
  </div>



  
  <!-- 移动对话框 -->
  <a-modal 
  v-model="dirModalVisible" 
  :title="dirModalTitle" 
  width="700rem"
  centered
  :ok-text="dirModalOkText" 
  cancel-text="取消" 
  @ok="handleMvcp">
    <div class="path">
      <template v-if="dirModalNavs.length === 1">
        全部文件
      </template>
      <template v-else>
        <a href="javascript:;" @click="mvcpGoback()">返回上一级</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="mvcpGoto(0)">全部文件</a>
        <template v-for="(v,i) of dirModalNavs">
          <template v-if="i !== 0">
            <template v-if="i === dirModalNavs.length-1">
              &nbsp;>&nbsp; {{v}}
            </template>
            <template v-else>
              &nbsp;>&nbsp; <a href="javascript:;" @click="mvcpGoto(i)" :key=i>{{v}}</a>
            </template>
          </template>
        </template>
      </template>
    </div>
    <div style="height:300rem;overflow-y:auto">
      <a-table 
      :columns="dirModalColumns" 
      :data-source="dirModalData"
      :pagination="false"
      :showHeader="false"
      :rowKey="(record,index) => index"
      >
      <template slot="name" slot-scope="text">
        <a-icon type="folder" />
        &nbsp;&nbsp;
          <a  href="javascript:;" @click="mvcpGonext(text)">{{text}}</a>
      </template>
      </a-table>
    </div>
  </a-modal>

  <!-- 删除对话框 -->
  <a-modal 
  v-model="removeModalVisible" 
  width="450rem"
  centered
  :style="{ fontSize: '14rem', color: '#1E202B', fontWeight:'600' }"
  @ok="handleRemove"
    @cancel="removeModalVisible = false"
    :cancelText="ll[languageId].cancel"
  :okText="ll[languageId].ok"
  :footer="null"

  >
  <template #title>
      <span style="font-size: 14rem;color:#000;font-weight: bold;">{{ll[languageId].confirm_delete}}</span>
    </template>
    <!-- <p style="text-align: center"><a-icon type="exclamation-circle" theme="twoTone" style="font-size:46rem"/></p> -->
    <p style="text-align: center">{{ll[languageId].confirm_delete}}{{deleteName}}?</p>

    <div style="margin-left:30rem;margin-right:30rem;height: 70rem;display: flex;justify-content: space-between;align-items: center;">
      <div @click="removeModalVisible = false" style="font-size: 15rem;color:#454545;font-weight: 400;">{{ll[languageId].cancel}}</div>
      <div style="color: #D8D8D8;">|</div>
      <div @click="handleRemove" style="font-size: 15rem;color:#000;font-weight: 600;">{{ll[languageId].ok}}</div>
    </div>
  </a-modal>



    <div id="download_div" v-show="showDownloadList">
  <div style="height: 42rem;border-bottom: 2rem solid #f6f6f6;background-color: #275B52;color: #fff;padding: 0 25rem ;">
    <a-row justify="space-between" type="flex" style="line-height: 40rem;">
      <a-col style="font-size: 14rem;">{{ll[languageId].download_list}} ({{successCount}}/{{ this.downloadProgressList.length }})</a-col>
      <a-col style="">
        <a-icon v-if="this.showDownloadProgressList" type="minus" @click="toggleDownloadList" />
        <a-icon v-else type="border" @click="toggleDownloadList" />
        &nbsp;&nbsp;<a-icon type="close" @click="closeDownloadList" />
      </a-col>
    </a-row>
  </div>
  <div style="height:338rem;overflow:hidden;;margin-top:5rem;margin-left: 25rem;margin-right: 25rem;" v-show="showDownloadProgressList">
    <div v-for="(progress, index) in downloadProgressList" :key="progress.id">
   

      <a-row  class="download_div_row">
          <a-col  style="width: 40%;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;color:#2A2C2F;font-size: 14rem;">{{ progress.filename }}</a-col>
          <a-col  style="width: 20%;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;color:#2A2C2F;font-size: 14rem;text-align: center;">{{ formatBytes(progress.size) }}</a-col>
          <a-col style="width: 40%;display: flex;align-items: center;justify-content: space-between">
            <div style="width: 75%;">
              <a-progress :percent="progress.percent" :status="progress.status"  strokeColor="#275B52"/>  
            </div>
            <a v-if="progress.status === 'exception'" @click="retryDownload(index)">{{ll[languageId].try_download}}</a>
          </a-col>
        </a-row>
    </div>
  </div>
</div>
    

 <!-- 添加二维码弹窗 -->
 <a-modal
    v-model="qrCodeModalVisible"
    height="628rem"
  width="433rem"
      centered
      :style="{ fontSize: '14rem', color: '#1E202B', fontWeight:'600' }"
      footer=""

  >
  <template #title>
      <span style="font-size: 14rem;color:#000;font-weight: bold;">{{ll[languageId].share}}：{{qrCodeName}}</span>
    </template>
    <div style="text-align: center;">
      <p>{{ll[languageId].scan}}</p>
      <!-- <p><canvas id="qrcode" ref="qrcode" class="canvas"></canvas></p> -->
      <p><a-button type="primary" style="background-color: #275B52;color: #fff;border-color: #275B52;width: 111rem;" @click="downloadQr(qrCodeName)">{{ll[languageId].download_code}}</a-button></p>
      <div class="weixinurl">{{qrCodeUrl}}</div>
      <p><a-button type="primary" style="background-color: #275B52;color: #fff;border-color: #275B52;width: 111rem;" @click="copyToClipboard(qrCodeUrl)">{{ll[languageId].copy_link}}</a-button></p>
      <p style="font-size: 12rem;color:#000;font-weight: 500;margin-top: 60rem;display: none;">{{ll[languageId].share_tips}}</p>

    </div>
  </a-modal>

  <a-modal 
    v-model="downloadModalVisible" 
    width="90%"
    height="auto"
    :style="{ fontSize: '14rem', color: '#1E202B', fontWeight:'600' }"
    centered
    @ok="confirmDownloadFormat"
    @cancel="cancelDownloadFormat"
    :cancelText="ll[languageId].cancel"
  :okText="ll[languageId].ok"
  :closable="false"
  :footer="null"
  >
    <template #title>
      <span style="font-size: 14rem;color:#000;font-weight: bold;">{{ll[languageId].choose_format}}</span>
    </template>

    <div style="width: 100%;height: 40rem;display: flex;align-items: center;justify-content: space-between;" v-for="(format, key) in availableFormats" :key="key">
      <div>{{ format }}</div>
      <div>
        <img v-if="selectedDownloadFormat==format" @click="selectedDownloadFormat=''" class="block_d" src="@/assets/wap/selected.png" alt="logo" style="width:22rem;height:23rem;margin-top: 2rem;">
        <img v-if="selectedDownloadFormat!=format" @click="selectedDownloadFormat=format"   class="block_d" src="@/assets/wap/unselect.png" alt="logo" style="width:22rem;height:23rem;margin-top: 2rem;">
      </div>

    </div>

    <div style="margin-left:30rem;margin-right:30rem;height: 70rem;display: flex;justify-content: space-between;align-items: center;">
      <div @click="cancelDownloadFormat" style="font-size: 15rem;color:#454545;font-weight: 400;">{{ll[languageId].cancel}}</div>
      <div style="color: #D8D8D8;">|</div>
      <div @click="confirmDownloadFormat" style="font-size: 15rem;color:#000;font-weight: 600;">{{ll[languageId].ok}}</div>
    </div>
  </a-modal>



  <div :class="['overlay', { active: isBottomSheetVisible }]" @click="closeBottomSheet">
      <div :class="['bottom-sheet', { active: isBottomSheetVisible }]" @click.stop>

      <div v-if="showType!=5" style="width: 52rem;height: 6rem;background: #D8D8D8;border-radius: 4rem;margin: 10rem auto;" @click.stop="closeBottomSheet"></div>
      <div v-if="showType==1" class="new_header_right">
        <div class="right_block">
          <div style="color:#000;font-size: 15rem;">{{ll[languageId].language}}</div>
          <div style="color:#7E7E7E;font-size: 14rem;">
              <div @click.stop="showType2" class="ant-dropdown-link"  style="display: flex;align-items: center;">
                <div>{{ languageName }}</div> 
                  <img  class="block_d" src="@/assets/wap/open.png" alt="logo" style="width:18rem;height:18rem;    margin-top: 2rem;">&nbsp;
              </div>
              
          </div>
        </div>
        <div class="right_block">
          <div style="color:#000;font-size: 15rem;">{{ll[languageId].device_name}}</div>

          <div style="color:#7E7E7E;font-size: 14rem;" v-if="deviceList.length<1">{{ll[languageId].current_no_device}}</div>
          <div style="color:#7E7E7E;font-size: 14rem;" >
              <div @click.stop="showType3" class="ant-dropdown-link"  style="display: flex;align-items: center;">
                <div>{{ deviceName }}&nbsp;-&nbsp;<span style="font-size: 11rem;">{{getMachineNumbers()}}</span></div> 
                  <img  class="block_d" v-if="deviceList.length>1" src="@/assets/wap/open.png" alt="logo" style="width:18rem;height:18rem;    margin-top: 2rem;">&nbsp;
              </div>
          </div>
          
        </div>

        <div class="right_block">
          <div style="color:#000;font-size: 15rem;">{{ll[languageId].file_time}}</div>

          <div style="color:#7E7E7E;font-size: 14rem;" >
              <div @click.stop="showType6" class="ant-dropdown-link"  style="display: flex;align-items: center;">
                <div>{{ timeName }}</div> 
                  <img  class="block_d"  src="@/assets/wap/open.png" alt="logo" style="width:18rem;height:18rem;    margin-top: 2rem;">&nbsp;
              </div>
          </div>
          
        </div>


        <div style="width:100%;height: 12rem;background-color: #FAFAFA;"></div>
    
        <div class="right_block" @click="userLogout" style="color:#E53A3A;justify-content: center;">
          <div>{{ll[languageId].exit}}</div>
        </div>
      </div>

      <div v-if="showType==2" class="new_header_right">
        <div class="right_block"  v-for="item in languageList" :key="item.id" @click="handleLanguage(item.id)">
          <div style="font-size: 15rem;" :style="item.id==languageId?'color:#29796B;':'color:#000;'">{{ item.name }}</div>
          <div><img v-if="item.id==languageId" class="block_d" src="@/assets/wap/on_select.png" alt="logo" style="width:18rem;height:18rem;margin-right: 10rem;"></div>
        </div>
      </div>
      <div v-if="showType==3" class="new_header_right">
        <div class="right_block" v-for="item in deviceList" :key="item.machineNumber" @click="handleDevice(item.machineNumber)">
          <div style="font-size: 15rem;display: flex;align-items: center;" :style="item.machineNumber==deviceId?'color:#29796B;':'color:#000;'">{{ item.modelName }}&nbsp;-&nbsp;<div style="font-size:11rem;">{{item.machineNumber}}</div></div>
          <div><img v-if="item.machineNumber==deviceId" class="block_d" src="@/assets/wap/on_select.png" alt="logo" style="width:18rem;height:18rem;margin-right: 10rem;"></div>
        </div>
      </div>

      <div v-if="showType==4" class="new_header_right" >
        <div style="font-size: 15rem;font-weight: 600;color:#000;margin-left:16rem;margin-top:16rem;">{{ll[languageId].share}}</div>
        <div style="display: flex;margin:32rem;margin-top:20rem;margin-bottom: 0;">
          <div @click="copyToClipboard()">
            <div style="display: flex;justify-content: center;">
              <img  src="@/assets/wap/copy_link.png" alt="logo" style="width:52rem;height:52rem;">
            </div>
            <div style="font-size: 12rem;color: #000;margin-top:10rem;">{{ll[languageId].copy_link}}</div>
        </div>

        <div style="margin-left: 30rem;" @click="lastConfirmShare">
          <div style="display: flex;justify-content: center;">
            <img  src="@/assets/wap/code.png" alt="logo" style="width:52rem;height:52rem;">
          </div>
          <div style="font-size: 12rem;color: #000;margin-top:10rem;">{{ll[languageId].create_code}}</div>
        </div>
        </div>
        <div style="font-size: 12rem;color:#757575;margin-left:32rem;margin-top:16rem;">{{ll[languageId].valid}}</div>
      </div>

      <div v-if="showType==5" class="new_header_right" style="    min-height: 200rem;max-height: 400rem;height: auto;overflow-y: auto;">
        <div style="display: flex;justify-content: space-between;align-items: flex-end;">
          <div style="font-size: 14rem;color:#656565;margin-left:16rem;margin-top:16rem;">{{ll[languageId].send_to}} {{deviceName}} ({{this.uploadFinallyCount()}}/{{ Object.keys(this.uploadList).length }})</div>
          <div v-if="this.uploadFinallyCount()==Object.keys(this.uploadList).length" @click="uploadList={}"><img   src="@/assets/wap/clear-1.png" alt="logo" style="width:18rem;height:18rem;margin-right: 16rem;"></div>
        </div>
        <div v-if="Object.keys(this.uploadList).length>0"> 
          <div  class="right_block" v-for="v in this.uploadList" :key="v.key" style="margin-top:20rem;margin-right: 18rem;">
          <div style="display: flex;flex-direction: column;width: calc(100% - 17rem);">
              <div style="width: 95%;font-size: 14rem;color:#3A3A3A;font-weight: bold;white-space: nowrap;overflow: hidden; text-overflow: ellipsis;">{{ v.filename }}</div>
              <div style="width: 95%;"><a-progress :percent="v.percent" :status="v.status"       class="custom-progress" strokeColor="#29796B"/></div>
          </div>
          <div v-if=" v.percent>=100"><img   src="@/assets/wap/up-s.png" alt="logo" style="width:15rem;height:16rem;"></div>
          <div v-if="v.status === 'success' && v.percent<100" ><img   src="@/assets/wap/up-f.png" alt="logo" style="width:15rem;height:16rem;"></div>
        </div>
        </div>
        <div v-else style="display: flex;flex-direction: column;align-items:center;margin-top:30rem;">
            <div><img  src="@/assets/wap/noList.png" alt="logo" style="width:89rem;height:93rem;"></div>
            <div style="margin-top:10rem;font-size:12rem;color:#000;">{{ll[languageId].no_data}}</div>
        </div>
      </div>


      <div v-if="showType==6" class="new_header_right">
        <div class="right_block" v-for="item in ll[languageId].timeList" :key="item.id" @click="handleTime(item.id)">
          <div style="font-size: 15rem;display: flex;align-items: center;" :style="item.id==timeId?'color:#29796B;':'color:#000;'">{{ item.name }}</div>
          <div><img v-if="item.id==timeId" class="block_d" src="@/assets/wap/on_select.png" alt="logo" style="width:18rem;height:18rem;margin-right: 10rem;"></div>
        </div>
      </div>

    </div>
  </div>

  <div v-if="selectedFruits.length>0 && downloadModalVisible==false && removeModalVisible==false && shareModalVisible==false && showDeviceList==false && isBottomSheetVisible==false"  class="overlay2">
     <div style="margin-left: 32rem;margin-right: 32rem;height: 100%;    display: flex;justify-content: space-between;flex-direction: row;">
        <div @click="handleDownload('')" style="display: flex;justify-content: flex-start;margin-top:10rem;align-items: center;flex-direction: column;">
          <div><img  class="" src="@/assets/wap/download.png" alt="logo" style="width:19rem;height:19rem;"></div>
          <div style="font-size: 10rem;color:#3A3A3A;margin-top:10rem;">{{ll[languageId].download}}</div>
        </div>
        <div @click="openRemoveModal('')" style="display: flex;justify-content: flex-start;margin-top:10rem;;align-items: center;flex-direction: column;">
          <div><img  class="" src="@/assets/wap/delete.png" alt="logo" style="width:19rem;height:19rem;"></div>
          <div style="font-size: 10rem;color:#3A3A3A;margin-top:10rem;">{{ll[languageId].delete}}</div>
        </div>
        <div @click="openShare()" style="display: flex;justify-content: flex-start;margin-top:10rem;;align-items: center;flex-direction: column;">
          <div>
            <img v-if="isCanShare" class="" src="@/assets/wap/share.png" alt="logo" style="width:19rem;height:19rem;">
            <img v-if="!isCanShare" class="" src="@/assets/wap/share-2.png" alt="logo" style="width:19rem;height:19rem;">
          </div>
          <div :style="isCanShare? 'color:#3A3A3A;':'color:#888;'" style="font-size: 10rem;margin-top:10rem;">{{ll[languageId].share}}</div>
        </div>
        <div @click="showDeviceListModal()" style="display: flex;justify-content: flex-start;margin-top:10rem;;align-items: center;flex-direction: column;">
          <div><img  class="" src="@/assets/wap/send.png" alt="logo" style="width:19rem;height:19rem;"></div>
          <div style="font-size: 10rem;color:#3A3A3A;margin-top:10rem;">{{ll[languageId].send}}</div>
        </div>
     </div>
    </div>


    
    <div v-if="copy_link"  class="overlay3"  @click="copy_link=false;">

      <div class="overlay3item">
        <div style="font-size: 14rem;font-weight: 500;color:#000;margin-left:16rem;margin-top:32rem;">{{ll[languageId].share_to}}</div>
      <div style="margin-top:20rem;;font-size: 14rem;color:#000;margin-left:16rem;margin-right:16rem;background-color: #F6F8F5;padding:16rem;word-break: break-all;">{{qrCodeUrl}}</div>
       
      <div @click.stop="copy_link=false;" style="margin-top:20rem;font-size: 12rem;color:#fff;margin-left:16rem;margin-top:16rem;margin-right: 16rem;background-color: #275B52;height: 42rem;    line-height: 42rem;text-align: center;">{{ll[languageId].yes}}</div>
      </div>
   
    </div>

    <div v-if="create_code"  class="overlay4"  @click="create_code=false;">

<div class="overlay4item">
  <div style="display: flex;justify-content: center;margin:40rem auto;;">
    <canvas id="qrcode" ref="qrcode" class="canvas"></canvas>
  </div>
 

<div 
style="margin-top:20rem;font-size: 12rem;color:#fff;margin-left:32rem;margin-top:16rem;margin-right: 32rem;margin-bottom: 32rem;background-color: #275B52;height: 42rem;    
line-height: 42rem;text-align: center;" @click.stop="downloadQr(qrCodeName)">{{ll[languageId].download_phone}}</div>
<div style="font-size: 12rem;color:#757575;margin:30rem 0;text-align: center;display: none;">{{ll[languageId].valid}}</div>

</div>

</div>

  <a-modal 
    v-model="shareModalVisible" 
    width="90%"
    height="auto"
    :style="{ fontSize: '14rem', color: '#1E202B', fontWeight:'600' }"
    centered
    @ok="confirmShare"
    @cancel="cancelShare"
    :cancelText="ll[languageId].cancel"
  :okText="ll[languageId].ok"
  :closable="false"
  :footer="null"
  >
    <template #title>
      <span style="font-size: 14rem;color:#000;font-weight: bold;">{{ll[languageId].choose_format}}</span>
    </template>

    <div style="width: 100%;height: 40rem;display: flex;align-items: center;justify-content: space-between;" v-for="(format, key) in availableFormats" :key="key">
      <div>{{ format }}</div>
      <div>
        <img v-if="selectedShareFormat==format" @click="selectedShareFormat=''" class="block_d" src="@/assets/wap/selected.png" alt="logo" style="width:22rem;height:23rem;margin-top: 2rem;">
        <img v-if="selectedShareFormat!=format" @click="selectedShareFormat=format"   class="block_d" src="@/assets/wap/unselect.png" alt="logo" style="width:22rem;height:23rem;margin-top: 2rem;">
      </div>
    </div>

    <div style="margin-left:30rem;margin-right:30rem;height: 70rem;display: flex;justify-content: space-between;align-items: center;">
      <div @click="cancelShare" style="font-size: 15rem;color:#454545;font-weight: 400;">{{ll[languageId].cancel}}</div>
      <div style="color: #D8D8D8;">|</div>
      <div @click="confirmShare" style="font-size: 15rem;color:#000;font-weight: 600;">{{ll[languageId].ok}}</div>
    </div>
  </a-modal>


  <a-modal 
    v-model="showDeviceList" 
    width="90%"
    height="auto"
    :style="{ fontSize: '14rem', color: '#1E202B', fontWeight:'600' }"


    :cancelText="ll[languageId].cancel"
  :okText="ll[languageId].ok"
  :closable="false"
  :footer="null"
  >
    <template #title>
      <span style="font-size: 14rem;color:#000;font-weight: bold;">{{ll[languageId].choose_device}}</span>
    </template>

    <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;padding-bottom:30rem;" v-for="item in filteredDeviceList" :key="item.id"  >
      <div style=" display: flex;align-items: center;">
          <div>
            <img  src="@/assets/wap/device_i.png" alt="logo" style="width:49rem;height:61rem;">
          </div>
          <div style="margin-left:15rem">
            <div style="    display: flex;">
              <div style="font-weight: 600;font-size: 14rem;color: #1E202B;">{{ item.modelName}}</div>
            </div>
            <div style="font-weight: 400;font-size: 10rem;color: #A1A1A1;">{{ll[languageId].device_number}} {{ item.machineNumber}}</div>
          </div>
        </div>
      <div>
        <img v-if="selectedmachineNumber == item.machineNumber"  @click="selectedmachineNumber=''" class="block_d" src="@/assets/wap/selected.png" alt="logo" style="width:22rem;height:23rem;margin-top: 2rem;">
        <img v-if="selectedmachineNumber != item.machineNumber"  @click="selectedmachineNumber=item.machineNumber"   class="block_d" src="@/assets/wap/unselect.png" alt="logo" style="width:22rem;height:23rem;margin-top: 2rem;">
      </div>

    </div>

    <div style="margin-left:30rem;margin-right:30rem;height: 70rem;display: flex;justify-content: space-between;align-items: center;">
      <div @click="showDeviceList=false" style="font-size: 15rem;color:#454545;font-weight: 400;">{{ll[languageId].cancel}}</div>
      <div style="color: #D8D8D8;">|</div>
      <div @click="hideDeviceListModal" style="font-size: 15rem;color:#000;font-weight: 600;">{{ll[languageId].ok}}</div>
    </div>
  </a-modal>


  <a-modal 
    v-model="showDeviceList" 
    width="90%"
    height="auto"
    :style="{ fontSize: '14rem', color: '#1E202B', fontWeight:'600' }"


    :cancelText="ll[languageId].cancel"
  :okText="ll[languageId].ok"
  :closable="false"
  :footer="null"
  >
    <template #title>
      <span style="font-size: 14rem;color:#000;font-weight: bold;">{{ll[languageId].choose_device}}</span>
    </template>

    <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;padding-bottom:30rem;" v-for="item in filteredDeviceList" :key="item.id"  >
      <div style=" display: flex;align-items: center;">
          <div>
            <img  src="@/assets/wap/device_i.png" alt="logo" style="width:49rem;height:61rem;">
          </div>
          <div style="margin-left:15rem">
            <div style="    display: flex;">
              <div style="font-weight: 600;font-size: 14rem;color: #1E202B;">{{ item.modelName}}</div>
            </div>
            <div style="font-weight: 400;font-size: 10rem;color: #A1A1A1;">{{ll[languageId].device_number}} {{ item.machineNumber}}</div>
          </div>
        </div>
      <div>
        <img v-if="selectedmachineNumber == item.machineNumber"  @click="selectedmachineNumber=''" class="block_d" src="@/assets/wap/selected.png" alt="logo" style="width:22rem;height:23rem;margin-top: 2rem;">
        <img v-if="selectedmachineNumber != item.machineNumber"  @click="selectedmachineNumber=item.machineNumber"   class="block_d" src="@/assets/wap/unselect.png" alt="logo" style="width:22rem;height:23rem;margin-top: 2rem;">
      </div>

    </div>

    <div style="margin-left:30rem;margin-right:30rem;height: 70rem;display: flex;justify-content: space-between;align-items: center;">
      <div @click="showDeviceList=false" style="font-size: 15rem;color:#454545;font-weight: 400;">{{ll[languageId].cancel}}</div>
      <div style="color: #D8D8D8;">|</div>
      <div @click="hideDeviceListModal" style="font-size: 15rem;color:#000;font-weight: 600;">{{ll[languageId].ok}}</div>
    </div>
  </a-modal>


  <a-modal 
    v-model="showPaperImport" 
    width="90%"
    height="auto"
    :style="{ fontSize: '14rem', color: '#1E202B', fontWeight:'600' }"


    :cancelText="ll[languageId].cancel"
  :okText="ll[languageId].ok"
  @cancel="cancelPaperImport"
  :closable="false"
  :footer="null"
  >
    <template #title>
      <span style="font-size: 14rem;color:#000;font-weight: bold;">{{ll[languageId].choose_paper_import}}</span>
    </template>

    <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;padding-bottom:30rem;" v-for="item in paperImportList[languageId]" :key="item.id"  >
      <div style=" display: flex;align-items: center;">
          <div>
            <img v-if="item.format =='.pdf'"  src="@/assets/pc/pdf.svg"  alt="logo" style="width:49rem;height:49rem;">
            <img v-if="item.format =='.png'"  src="@/assets/pc/template.svg"  alt="logo" style="width:49rem;height:49rem;">
          </div>
          <div style="margin-left:15rem">
            <div style="    display: flex;">
              <div style="font-weight: 600;font-size: 14rem;color: #1E202B;">{{ item.title}}</div>
            </div>
            <div style="font-weight: 400;font-size: 10rem;color: #A1A1A1;">{{ll[languageId].smallTitle}}</div>
          </div>
        </div>
      <div>
        <img v-if="selectedFormat == item.format"  @click="selectedFormat=''" class="block_d" src="@/assets/wap/selected.png" alt="logo" style="width:22rem;height:23rem;margin-top: 2rem;">
        <img v-if="selectedFormat != item.format"  @click="selectedFormat=item.format"   class="block_d" src="@/assets/wap/unselect.png" alt="logo" style="width:22rem;height:23rem;margin-top: 2rem;">
      </div>

    </div>

    <div style="margin-left:30rem;margin-right:30rem;height: 70rem;display: flex;justify-content: space-between;align-items: center;">
      <div @click="cancelPaperImport" style="font-size: 15rem;color:#454545;font-weight: 400;">{{ll[languageId].cancel}}</div>
      <div style="color: #D8D8D8;">|</div>
      <div @click="paperImportComfirm" style="font-size: 15rem;color:#000;font-weight: 600;">{{ll[languageId].ok}}</div>
    </div>
  </a-modal>
  </div>
</template>
<script>

import {mkdir,
list,
uploadCheck,
uploadFile,
fileRemove,
rename,
getFileTypeList,
downloadFile,
getFileExt,
sharedCreate,
mvcp} from "@/api/api"
import SparkMD5 from "spark-md5";
import  storage from 'store'
import {makeSharedLink} from "@/utils/common"
import { refreshLogin,getDeviceList,sendFiled,getTimeId,updateFileCacheTime} from '@/api/api'
import { setToken,setMachineModel,setMachineNumber,getMachineNumber,getMachineModel,setLanguage,getToken,getApiHeader,getLanguage,getDir,setDir,getCurrentDir,setCurrentDir} from '@/api/auth'
// import Pagination from "@/components/Pagination";  //1. 引入 Pagination.vue 组件，注意路径哦～
import request from '@/utils/request';
import QRCode from 'qrcode'
import { nextTick } from 'vue'
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
export default {
  name: 'vitransfer',
  components: {
  },
  data () {
    return {
      columns:[
        {title:'文件名',dataIndex: 'filename',width:'55%',scopedSlots: { customRender: 'name' }},
        {title:'大小',dataIndex: 'size',width:'20%',},
        {title:'修改时间',dataIndex: 'date'},
      ],
      selectedRowKeys:[],
      selectedNames:[],

      root:"cloud",
      navs :[],
      resData:[],

      // 删除
      removeModalVisible: false,

      // 新建目录
      addFolder: false,
      addFolderName:"",

      // 上传文件
      uploadDirectory:false,
      uploadFileOpen:false,

      // 重命名
      renameIndex:-1,
      renameValue:"",

      // 移动、拷贝
      dirModalVisible:false,
      dirModalTitle:"",
      dirModalOkText:"",
      dirModalNavs:[],
      dirModalColumns:[
        {title:'文件名',dataIndex: 'filename',scopedSlots: { customRender: 'name' }},
      ],
      dirModalData:[],
      mvcpMove:false,
      mvcpSource:[],
      mvcpTarget:"",
      
      // 传输列表
      showTransfer:false,
      showTransferUploadList:true,
      uploadList:{},

      // 分享
      shareModalVisible:false,
      shareTitle:"",
      shareCreate:true,
      shareTime:1,
      sharedRoute:"",
      sharedToken:"",
      sharedDeadline:0,
      sharedCopyText:"",
      sharedCopied:false,

      deviceList:[],
      paperImportList:{
        "zh":[
          {"img":"pdf.svg","format":".pdf","title":"PDF文档","smallTitle":"支持PDF格式"},
          {"img":"template.svg","format":".png","title":"PNG 模板","smallTitle":"支持PNG格式"}
        ],
        "en":[
          {"img":"pdf.svg","format":".pdf","title":"PDF document","smallTitle":"Supports PDF Format"},
          {"img":"template.svg","format":".png","title":"PNG template","smallTitle":"Supports PNG Format"}
        ],
      },
      deviceName:'',
      deviceId:'',
      languageName:'English',
      languageId:"en",
      languageList:[
        {"id":"zh","name":"中文"},
        {"id":"en","name":"English"},
      ],
      ll: {
        "en":{
          "timeList":[
            {"id":"86400","name":"24 Hours","desc":"Expires 24 hours after upload"},
            {"id":"7200","name":"2 Hours","desc":"Expires 2 hours after upload"},
            {"id":"1800","name":"30 Minutes","desc":"Expires 30 minutes after upload"},
          ],
          "choose_paper_import": "Select import type",
          "current_device": "Current Device Name",
          "choose_device": "Choose Device",
          "choose_language": "Choose Language",
          "exit": "Log out",
          "paper_name": "Paper",
          "meeting_name": "Meeting",
          "learning_name": "Learning",
          "daily_name": "Daily",
          "picking_name": "Picking",
          "memo_name": "Memo",
          "other_name": "Other",

          "name": "Name",
          "type": "Type",
          "size": "Size",
          "time": "DateTime",
          "operation": "Operation",

          "download": "Download",
          "delete": "Delete",
          "import": "Import",
          "return":"Return Pre",
          "search_all":"Search All",
          "selected":"",
          "selected_file":" files selected",
          "file":"File",
          "folder":"Folder",
          "no_data":"No Data",

          "confirm_delete":"Confirm Delete ",
          "send_to":"To",
          "download_list":"Download List",
          "device_number":"Device Number",
          "scan":"Scan the code or enter the address",
          "share":"Share",
          "download_code":"Download",
          "create_code":"Create QR Code",
          "share_to":"Link copied successfully, share it with friends now!",

          "copy_link":"Copy Link",
          "share_tips":"Note: The shared link/QR code is valid for 24 hours",
          "choose_format":"Choose Format",
          "please_choose":"Please choose folder or file",
          "please_choose_format":"Please choose format",
          "format_not_exists":"The format not exists",
          "copy_success":"Copy Success",
          "copy_fail":"Copy Fail",
          "close_download_tips":"There are files being downloaded and the list cannot be closed.",
          "choose_send_device":"Please choose send device",
          "send_success":"Send Success",
          "send_fail":"Send Fail",
          "cancel":"Cancel",
          "ok":"OK",
          "send":"Send",
          "no_device":"Please log in again on the machine to automatically add the device",
          "current_no_device":"No Device",
          "current_device_tips":"Current Device",
          "send_loading_text":"Sending",
          "loading_text":"Loading",
          "send_error":"Send Fail",
          "try_download":"Retry",
          "error":"Server Error",
          "language": "Language",
          "device_name": "Device Name",
          "selecte_all": "Select All",
          "unselecte_all": "Deselect All",
          "so":" so files",
          "valid":"The link/QR code is valid for 24 hours",
          "download_phone":"Download",
          "yes":"Yes",
          "max_size":"The file size exceeds 100MB and cannot be uploaded.",
          "file_time":"File Expires in",


        },
        "zh":{
          "timeList":[
            {"id":"86400","name":"24小时","desc":"上传后24小时内有效"},
            {"id":"7200","name":"2小时","desc":"上传后24小时内有效"},
            {"id":"1800","name":"30分钟","desc":"上传后30分钟内有效"},
          ],
          "choose_paper_import": "选择导入类型",
          "current_device": "当前设备",
          "choose_device": "选择设备",
          "choose_language": "选择语言",
          "exit": "退出登录",
          "paper_name": "笔记",
          "meeting_name": "会议",
          "learning_name": "学习",
          "daily_name": "日程",
          "picking_name": "随记",
          "memo_name": "便签",
          "other_name": "其他",

          "name": "名称",
          "type": "类型",
          "size": "大小",
          "time": "时间",
          "operation": "操作",

          
          "download": "下载",
          "delete": "删除",
          "import": "导入",
          "return":"返回上一级",
          "search_all":"搜索全部文件",
          "selected":"已选中",
          "selected_file":" 文件",
          "file":"文件",
          "folder":"文件夹",
          "no_data":"暂无数据",
          "share_to":"链接复制成功，赶紧分享给朋友吧！",
          "confirm_delete":"确认删除",
          "device_number":"序列号",
          "send_to":"发送至",
          "download_list":"下载列表",
          "scan":"扫码或输入地址",
          "share":"分享",
          "create_code":"生成二维码",
          "download_code":"下载二维码",
          "copy_link":"复制链接",
          "share_tips":"注：分享的链接/二维码有效期为24小时",
          "choose_format":"选择格式",
          "please_choose":"请选择文件夹或者文件",
          "please_choose_format":"请选择格式",
          "format_not_exists":"该格式的文件不存在",
          "copy_success":"复制成功",
          "copy_fail":"复制失败",
          "close_download_tips":"有正在下载的文件，不能关闭列表。",
          "choose_send_device":"请选择同步的设备",
          "send_success":"同步成功",
          "send_fail":"同步出错",
          "cancel":"取消",
          "ok":"确认",
          "send":"同步",
          "no_device":"暂无设备，请在机器上重新登录，即可自动添加设备",
          "current_no_device":"暂无设备",
          "current_device_tips":"当前设备",
          "send_loading_text":"同步中",
          "loading_text":"加载中",
          "send_error":"同步失败",
          "try_download":"重试",
          "error":"服务异常",
          "language": "语言",
          "device_name": "设备名称",
          "selecte_all": "全选",
          "unselecte_all": "取消全选",
          "so":"等文件",
          "valid":"链接/二维码有效期为 24 小时",
          "download_phone":"下载到手机",
          "yes":"知道了",
          "max_size":"文件的大小不能超过100M",
          "file_time":"文件有效时间"

        }
      },

      fileTypeList:[
          {
            dir: "/learning/",
            num: "0",
          },
          {
          dir: "/paper/",
          num: "0",
          upload:"1",
          },
          {
            dir: "/meeting/",
            num: "0",
          },
          {
            dir: "/picking/",
            num: "0",
          },
          {
            dir: "/daily/",
            num: "0",
          },
          {
            dir: "/memo/",
            num: "0",
          },
          {
            dir: "/other/",
            num: "0",
          },
      ],
      fileTypeListId:"/paper/",
      preDir:"",
      dir:"/paper/",
      fileName:"",
      isFocused:false,
       pagination:{ //分页数据
        current : 1,
        pageSize: 10,
        totalCount:500
      },

      selectedFruits:[],
      showDeviceList:false,
      showPaperImport:false,

      selectedmachineNumber:"",
      
      showDownloadList: false,
      showDownloadProgressList: true,
      downloadProgressList: [],
      deleteId:"",

      qrCodeModalVisible: false,
      qrCodeUrl: '',
      qrCodeName:'',

      formatList:{
        "/paper/":{
          "pdf":"pdf",
          "png":"png",
          "jpg":"jpg",
          "viwoods":"viwoods",
        },
        "/meeting/":{
          "pdf":"pdf",
          "png":"png",
          "jpg":"jpg",
          "viwoods":"viwoods",
        },
        "/learning/":{
          "pdf":"pdf",
          "png":"png",
          "jpg":"jpg",
          "epub":"epub",
          "viwoods":"viwoods",
        },
        "/picking/":{
          "pdf":"pdf",
          "png":"png",
          "jpg":"jpg",
          "viwoods":"viwoods",
        },
        "/memo/":{
          "pdf":"pdf",
          "png":"png",
          "viwoods":"viwoods",
        },
        "/daily/":{
          "pdf":"pdf",
          "png":"png",
          "viwoods":"viwoods",
        },
      },
      downloadModalVisible: false,
      selectedDownloadFormat: '',
      selectedShareFormat: '',
      availableFormats: {},
      downloadFileId: '',
      loading: true, // 新增加载状态
      fileTypesString:"",

      downLoadList:{},
      shareItem:{},

      isBottomSheetVisible:false,
      showType:1,
      deleteName:'',
      copy_link:false,
      create_code:false,

      isCanShare:false,


      overallProgress: null,
      files: [
        // { name: 'file1.txt', url: 'https://example.com/file1.txt' },
        // { name: 'file2.txt', url: 'https://example.com/file2.txt' },
        // 添加其他文件...
      ],
      fileProgress: [],
      timeName:"",
      timeId:0,
      selectedFormat:".pdf"
    }
  },
  computed: {
     successCount() {
      let total = 0;
      for (var item of this.downloadProgressList) {
        if(item.status == 'success') {
          total += 1
        }
       
      }
      return total;
    },

    filteredDeviceList() {
      // 过滤掉 type 为 1 的设备
      return this.deviceList.filter(item => this.getMachineNumbers()!=item.machineNumber);
    }
  
  },
  mounted () {
    let ll = getLanguage()
    if (ll == "" || ll == undefined) {
      const selectedLanguage = this.languageList.find(item => item.id === this.languageId);
      if (selectedLanguage) {
        this.languageName = selectedLanguage.name;
        setLanguage(selectedLanguage.id)
      }
    } else {
      this.languageId = ll
      const selectedLanguage = this.languageList.find(item => item.id === this.languageId);
      if (selectedLanguage) {
        this.languageName = selectedLanguage.name;
      }
    }
    let dir = getDir()
    console.info("dirdir",dir)
    if(dir) {
      this.fileTypeListId = dir
    }

    let currentDir = getCurrentDir()
    if(currentDir) {
      this.dir = currentDir
    } else {
      this.dir = this.fileTypeListId
    }
    this.refreshLogins();
    this.getUserDeviceList();
    this.setFileTypesString();
    this.getTimeNames()

  },
 
  methods:{
    getTimeNames(){
      getTimeId({}).then(ret => {
            if(ret.data.code == 200) {
              this.timeId = ret.data.data.fileCacheTime
              this.ll[this.languageId].timeList.forEach((tab) => {
                if(tab.id == this.timeId) {
                  this.timeName = tab.name
                }
              });
            } 
          }).catch(() => {
          })
    },
    handleTime(id) {
      this.timeId = id;
      for (let i = 0; i < this.ll[this.languageId].timeList.length; i++) { // 遍历行
        if(this.ll[this.languageId].timeList[i].id == this.timeId) {
          this.timeName = this.ll[this.languageId].timeList[i].name;
          this.timeId = this.ll[this.languageId].timeList[i].id
          updateFileCacheTime({time:this.ll[this.languageId].timeList[i].id})
          this.showType=1;

        }
      }
    },
    showType2(){
      this.showType=2
    },
    showType3(){
      if(this.deviceList.length<2) {
        return;
      }
      this.showType=3
    },
    showType4(){
      this.showType=4
    },
 
    showType6(){
      this.showType=6
    },
 
    handleUploadType2(){
      //判断是否有下载中的操作
      for (var item of this.downloadProgressList) {
        if(item.status != 'success') {
          return this.$message.error("请等待下载完再导入")
        }
      }
      this.showDownloadList = false;
      this.uploadDirectory = false

      this.showPaperImport = true
      // this.$nextTick(() =>{
      //   this.$refs.upload.$refs.uploadRef.$el.firstChild.click();
      //   this.uploadFileOpen = false
      // })
    },
    openBottomSheet(type) {
      this.isBottomSheetVisible = true;
      this.showType = type
    },
    closeBottomSheet() {
      if(this.showType == 1) {
        this.isBottomSheetVisible = false;
        return
      }
      if(this.showType == 4) {
        this.isBottomSheetVisible = false;
        // this.shareModalVisible=false;
        this.showType = 1;
        return
      }
      if(this.showType == 5) {
        this.isBottomSheetVisible = false;
        this.showType = 1;
        return
      }

      if(this.showType !=1) {
        this.showType = 1;
      }
    },
    paperImportComfirm(){
      //判断是否有下载中的操作
      if(this.selectedFormat == "") {
        return this.$message.error(this.ll[this.languageId].choose_paper_import)
      }
      this.fileTypesString =  this.selectedFormat
      console.info(" this.fileTypesString", this.fileTypesString)
      this.showPaperImport = false;
            this.selectedFormat = ".pdf";

      this.$nextTick(() =>{
        this.$refs.upload.$refs.uploadRef.$el.firstChild.click();
        this.uploadFileOpen = false
        this.selectedFormat = ".pdf";

      })
    },
    cancelPaperImport(e) {
      console.info(e)
      this.showPaperImport = false
      this.selectedFormat = ".pdf"
    },
    formatDate(inputDate) {
      // 创建一个新的 Date 对象
      const date = new Date(inputDate);

      // 获取年、月、日
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
      const day = String(date.getDate()).padStart(2, '0');

      // 返回格式化后的日期字符串
      return `${year}/${month}/${day}`;
    },
    removePrefix  (path, prefix) {
      if (path.startsWith(prefix)) {
        return path.slice(prefix.length).replace(/^\/|\/$/g, '');
      }
      return path.replace(/^\/|\/$/g, '');
    },
    getMachineNumbers() {
      return getMachineNumber();
    },
    setFileTypesString() {
      let fileTypes = [];
      if(this.dir == "/paper/") {
        fileTypes =  ['.pdf','.png'];
      }
      if(this.dir == "/meeting/") {
        fileTypes =  ['.png'];
      }
      if(this.dir == "/learning/") {
        fileTypes =  ['.pdf','.epub'];
      }
      if(this.dir == "/picking/") {
        fileTypes =  [];
      }
      if(this.dir == "/daily/") {
        fileTypes =  [];
      }
      if(this.dir == "/memo/") {
        fileTypes =  [];
      }
      return this.fileTypesString = fileTypes.join(',')
    },
    downloadQr(name) {
      const canvas = document.getElementById('qrcode');
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = name + '.png';
      link.click();
    },
     copyToClipboard() {
      if(this.shareModalVisible && this.selectedShareFormat == "") {
        return this.$message.error(this.ll[this.languageId].please_choose_format)
      }

      for (let i = 0; i < this.shareItem.origin.length; i++) {
          let selectName = this.selectedShareFormat
          if(selectName == "png") {
            selectName = "zip"
          }
          console.info("this.selectedShareFormat222",this.selectedShareFormat)
          if(this.shareItem.origin[i].path.includes(selectName)) {
            this.qrCodeUrl = this.shareItem.origin[i].path
          }
        }

      this.copy_link = true

      try {
        // 创建一个临时的输入元素
        const input = document.createElement('input');
        // 将需要复制的文本赋值给临时输入元素
        input.setAttribute('value', this.qrCodeUrl);
        // 将临时输入元素添加到文档中
        document.body.appendChild(input);
        // 选择输入元素中的文本
        input.select();
        // 执行复制命令
        document.execCommand('copy');
        // 移除临时输入元素
        document.body.removeChild(input);
    


        this.$message.success(this.ll[this.languageId].copy_success)
      } catch (err) {
        this.$message.error(this.ll[this.languageId].copy_fail)
      }
    },
    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 B'; // 如果字节数为0，返回 "0 B"

        const k = 1024; // 定义千字节
        const dm = decimals < 0 ? 0 : decimals; // 保证小数点位数不为负数
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']; // 定义单位数组

        // 计算单位的序号
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        // 计算格式化的数值并返回带单位的字符串
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
   
    generateQRCode(text) {
      const canvas = document.getElementById('qrcode')
      // const canvas = this.$refs.qrcode
      if (!canvas) {
        console.error('Canvas element not found')
        return
      }
      console.info("canvascanvas",canvas)
      QRCode.toCanvas(canvas, text, function (error) {
        if (error) console.error("errorerrorerror",error)
        console.log('success!')
      })
    },
    handleDownload(o) {
      this.downloadModalVisible = false;
      this.availableFormats = {}
      this.downLoadList = {}
      this.downloadFileId = ""
      this.selectedDownloadFormat = ""
      if (o == '' && this.selectedFruits.length==0) {
        return this.$message.error(this.ll[this.languageId].please_choose)
      }
      if(o) {
        this.downloadFileId = o.id;
      } 
      
      let args = {}
      if (o) {
        let ids = [];
        ids.push(o.id);
        args = { id: JSON.stringify(ids) };
      } else {
        args = { id: JSON.stringify(this.selectedFruits) };
      }
           // 开始加载动画
       // 开始加载动画
       const loading = this.$loading({
            lock: true,
            text: this.ll[this.languageId].loading_text,
            customClass: 'custom-loading'
          });

      getFileExt(args).then(res => {
        console.info("res.data", res.data.data);
        if (!res.data.data ) {
          return this.$message.error(this.ll[this.languageId].format_not_exists);
        }

        for (const key of res.data.data) {
          this.availableFormats[key]=key
        }

        for (const key of res.data.data) {
          this.availableFormats[key]=key
          if(this.selectedDownloadFormat == "") {
            this.selectedDownloadFormat = key
          }
          if(key=="pdf") {
            this.selectedDownloadFormat = key
          }
          if(key=="epub") {
            this.selectedDownloadFormat = key
          }
        }


        // this.availableFormats = this.formatList[this.fileTypeListId];
        if( Object.keys(this.availableFormats).length<=1) {
          for (const format in this.availableFormats) {
            this.selectedDownloadFormat = format
          }
          this.confirmDownloadFormat()
        } else {
          this.downloadModalVisible = true;
        }
        loading.close()
      });

 
    },
    openShare() {
      if(!this.isCanShare) {
        return
      }

      this.resData.forEach((tab) => {
        console.info("shareItemtab.id",tab.id,this.selectedFruits[0])

        if(tab.id == this.selectedFruits[0] && tab.type==2) {
            this.shareItem = tab
            return
          }
        });


    
        console.info("shareItem",this.shareItem)

        if(JSON.stringify(this.shareItem) === '{}') {
          console.info("shareItem",this.shareItem)
          return;
        }

      if(this.shareItem.format.length>1) {
        for (const key of this.shareItem.format) {
          if(this.selectedShareFormat =="") {
            this.selectedShareFormat = key
          }
          this.availableFormats[key]=key
          if(key=="pdf") {
            this.selectedShareFormat = key
          }
          if(key=="epub") {
            this.selectedShareFormat = key
          }
        }
        console.info("this.selectedShareFormat1",this.selectedShareFormat)
        this.shareModalVisible = true;
        // this.showType4()
      } else {
        this.confirmShare()
      }
    },
    confirmShare() {
      if(this.shareModalVisible && this.selectedShareFormat == "") {
        return this.$message.error(this.ll[this.languageId].please_choose_format)
      }
      this.openBottomSheet(4)
    },
    lastConfirmShare() {
      if(this.shareModalVisible && this.selectedShareFormat == "") {
        return this.$message.error(this.ll[this.languageId].please_choose_format)
      }

      console.info("this.selectedShareFormatstart",this.selectedShareFormat,this.shareItem.origin,this.shareItem.origin.length)
      let i = 0
        for (; i < this.shareItem.origin.length; i++) {
          let  selectName = this.selectedShareFormat
          if (selectName == "png") {
            selectName = "zip"
          }
          if(this.shareItem.origin[i].path.includes(selectName)) {
            this.qrCodeName = this.shareItem.origin[i].name
            const lastDotIndex = this.shareItem.origin[i].name.lastIndexOf('.');
            if (lastDotIndex === -1) {
              this.qrCodeName =this.shareItem.origin[i].name;
            }
            this.qrCodeName = this.shareItem.origin[i].name.substring(0, lastDotIndex);
            this.qrCodeName += "_code"

            this.qrCodeUrl = this.shareItem.origin[i].path
            console.info("this.qrCodeUrl11",this.qrCodeUrl)
            nextTick(() => {
              this.generateQRCode(this.qrCodeUrl)
            })
            // this.generateQRCode(this.qrCodeUrl)
            this.create_code=true
            break
          }
        }



        

       
    },
    async confirmDownloadFormat() {
      let selectName = this.selectedDownloadFormat
      
      try {
        if(Object.keys(this.availableFormats).length > 1  &&this.selectedDownloadFormat == '') {
        return this.$message.error(this.ll[this.languageId].please_choose_format)
      }
      let args = {}
      if (this.downloadFileId != '') {
        let ids = [];
        ids.push(this.downloadFileId);
        args = { id: JSON.stringify(ids), format: selectName };
      } else {
        args = { id: JSON.stringify(this.selectedFruits), format: selectName };
      }

      // 开始加载动画
      const loading = this.$loading({
            lock: true,
            text: this.ll[this.languageId].loading_text,
            customClass: 'custom-loading'
          });
      await  downloadFile(args).then(res => {
        loading.close();
        console.info("res.data", res.data.data);
        if (!res.data.data || res.data.data.length < 1) {
          return this.$message.error(this.ll[this.languageId].format_not_exists);
        }
        // for (const key of res.data.data) {
        //   this.files.push(key.path)
        // }
        this.files = res.data.data
        console.info("this.filesthis.filesthis.files",this.files)

        
           }).catch(() => {
             // 结束加载动画
            loading.close();
            return this.$message.error(this.ll[this.languageId].error)

          });



        this.overallProgress = 0;
        this.fileProgress = new Array(this.files.length).fill(0);

        if (this.files.length === 1) {
          // 只有一个文件时，直接下载该文件
          const file = this.files[0];
          console.info("filefilefile",file)
          const response = await this.downloadFiles(file.path, 0);
          const blob = new Blob([response.data], { type: response.headers['content-type'] });
          saveAs(blob, file.name);
        } else {
          const zip = new JSZip();

          for (let i = 0; i < this.files.length; i++) {
            const file = this.files[i];
            const response = await this.downloadFiles(file.path, i);
            zip.file(file.name, response.data);
          }

          const zipBlob = await zip.generateAsync({ type: 'blob' });
          saveAs(zipBlob, 'files.zip');
          this.overallProgress = null;
        }
        
      } catch (error) {
        console.error('Download failed', error);
        this.overallProgress = null;
      }
    },
    downloadFiles(url, index) {
      return axios.get(url, {
        responseType: 'arraybuffer',
        onDownloadProgress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.$set(this.fileProgress, index, percentCompleted);
            this.updateOverallProgressPartial();
          }
        },
      });
    },
    updateOverallProgressPartial() {
      const totalProgress = this.fileProgress.reduce((acc, curr) => acc + curr, 0);
      this.overallProgress = Math.round(totalProgress / this.files.length);
    },

    confirmDownloadFormat1() {
      if(Object.keys(this.availableFormats).length > 1  &&this.selectedDownloadFormat == '') {
        return this.$message.error(this.ll[this.languageId].please_choose_format)
      }
      let args = {}
      if (this.downloadFileId != '') {
        let ids = [];
        ids.push(this.downloadFileId);
        args = { id: JSON.stringify(ids), format: this.selectedDownloadFormat };
      } else {
        args = { id: JSON.stringify(this.selectedFruits), format: this.selectedDownloadFormat };
      }

        // 开始加载动画
        const loading = this.$loading({
            lock: true,
            text: this.ll[this.languageId].loading_text,
            customClass: 'custom-loading'
          });


      downloadFile(args).then(res => {
        loading.close();
        console.info("res.data", res.data.data);
        if (!res.data.data || res.data.data.length < 1) {
          return this.$message.error(this.ll[this.languageId].format_not_exists);
        }
        for (var item of res.data.data) {
          console.info("item", item);
          this.downloadFile(item);
        }
           }).catch(() => {
             // 结束加载动画
            loading.close();
            return this.$message.error(this.ll[this.languageId].error)

          });
    },
    cancelDownloadFormat() {
      this.downloadModalVisible = false;
      this.selectedDownloadFormat = '';
      this.availableFormats = {}

    },
    cancelShare() {
      this.shareModalVisible = false;
      this.selectedShareFormat = '';
      this.availableFormats = {}
      this.shareItem = {}
    },

// 其他方法保持不变
async downloadFile(file, startByte = 0,index=-1) {
  const progress = {
    hash: file.hash,
    filename: file.name,
    percent: 0,
    status: "active",
    xhr: null,
    downloadedBytes: startByte,
    size:file.size
  };
  if(index > -1) {
    this.downloadProgressList[index] = progress
  } else {
    this.downloadProgressList.push(progress);
  }
  this.showDownloadList = true;
  console.info("this.downloadProgressList",this.downloadProgressList)

  try {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", file.path, true);
    xhr.responseType = "blob";
    xhr.setRequestHeader("Range", `bytes=${startByte}-`);

    xhr.onprogress = (event) => {
      if (event.lengthComputable) {
        progress.downloadedBytes = startByte + event.loaded;
        progress.percent = Math.round((progress.downloadedBytes * 100) / file.size);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200 || xhr.status === 206) {
        progress.status = "success";
        const blob = new Blob([xhr.response]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = file.name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        progress.status = "exception";
        throw new Error("Download failed");
      }
    };

    xhr.onerror = () => {
      progress.status = "exception";
      console.error("Download failed");
    };

    xhr.onabort = () => {
      progress.status = "paused";
    };

    xhr.setRequestHeader("Authorization", "Bearer " + getToken());

    progress.xhr = xhr;
    xhr.send();
  } catch (error) {
    progress.status = "exception";
    console.error("Download failed:", error);
  }
},

pauseDownload(index) {
  const progress = this.downloadProgressList[index];
  if (progress && progress.xhr) {
    progress.xhr.abort();
    progress.status = "paused";
  }
},

resumeDownload(index) {
  const progress = this.downloadProgressList[index];
  if (progress && progress.status === "paused") {
    const file = this.downLoadList.find((item) => item.id === progress.id);
    if (file) {
      this.downloadFile(file, progress.downloadedBytes);
    }
  }
},

deleteDownload(index) {
  this.downloadProgressList.splice(index, 1);
},
retryDownload(index) {
  const progress = this.downloadProgressList[index];
  console.info("progressprogress",progress)
  if (progress ) {

    this.downLoadList.forEach((tab) => {
      if(tab.hash == progress.hash) {
        this.downloadFile(tab, 0,index);
      }
    });
  }
},

toggleDownloadList() {
  this.showDownloadProgressList = !this.showDownloadProgressList;
},

closeDownloadList() {
  const activeDownloads = this.downloadProgressList.filter(progress => progress.status === "active");
  if (activeDownloads.length > 0) {
    return this.$message.info(this.ll[this.languageId].close_download_tips)
  } else {
    // this.downloadProgressList = [];
    this.showDownloadList = false;
  }
},
    
    showDeviceListModal() {
      this.showDeviceList = true
    },
    hideDeviceListModal(e) {
      console.log(e);
      if(this.selectedmachineNumber=="") {
        return this.$message.error(this.ll[this.languageId].choose_send_device)
      }
        // 开始加载动画
      // 开始加载动画
      const loading = this.$loading({
            lock: true,
            text: this.ll[this.languageId].loading_text,
            customClass: 'custom-loading'
          });

      sendFiled({"id":JSON.stringify(this.selectedFruits),"to":this.selectedmachineNumber}).then(ret => {
            // 结束加载动画
            setTimeout(() => {
              loading.close();
              if(ret.data.code == 200) {
              console.info("ret.data",ret.data)
              this.$message.success(this.ll[this.languageId].send_success)
              this.showDeviceList = false
              this.selectedmachineNumber = ""
            } else {
              return this.$message.error(this.ll[this.languageId].send_error)
            }
            }, 5000);
           
          }).catch(() => {
             // 结束加载动画
            loading.close();
            return this.$message.error(this.ll[this.languageId].send_error)

          })
    },
    isImageFile(filePath) {
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
      const extension = filePath.split('.').pop().toLowerCase();
      return imageExtensions.includes(extension);
    },
    getImageSrc(path) {
      const extension = path.split('.').pop().toLowerCase();
      try {
        return require(`@/assets/wap/${extension}-ic.png`);
      } catch (error) {
        return require(`@/assets/wap/unkown.png`);
      }
    },
    isSelected(key) {
      return this.selectedFruits.includes(key);
    },
    
    updateSelection(value){
      
      if (!this.selectedFruits.includes(value)) {
        this.selectedFruits.push(value);
        console.info("valuevalue",value,this.selectedFruits)
      } else if ( this.selectedFruits.includes(value)) {
        this.selectedFruits = this.selectedFruits.filter(fruit => fruit !== value);
      }

      if(this.selectedFruits.length != 1) {
        this.isCanShare= false
        return
      }
      this.isCanShare=  true;
      this.resData.forEach((tab) => {
        console.info("tabtabtab",this.selectedFruits[0],tab)
          if(tab.id==this.selectedFruits[0] && tab.type==1) {
            this.isCanShare= false
            return
          } 
        });
        
    },
    selectAll() {
      if(this.selectedFruits.length > 0 && this.selectedFruits.length<this.resData.length ) {
        this.selectedFruits = [];
        this.resData.forEach((tab) => {
          this.selectedFruits.push(tab.id)
        });
      } else {
        this.selectedFruits = [];
      }
    },
   
    getUserDeviceList(){
      getDeviceList({"pageSize":"100"}).then(ret => {
            if(ret.data.code == 200) {
              this.deviceList = ret.data.data.list || []
              if(this.deviceList.length > 0) {
                if(!getMachineNumber()) {
                  this.deviceId = this.deviceList[0].machineNumber
                  this.deviceName = this.deviceList[0].modelName
                  setMachineModel(this.deviceList[0].modelName)
                  setMachineNumber(this.deviceList[0].machineNumber)
                } else {
                  this.deviceId = getMachineNumber()
                  this.deviceName = getMachineModel()
                }
                this.getUserFileTypeList()
              
              } else {
                setTimeout(() => {
                  this.loading=false
                }, 500);
                this.$message.info({
                  content: this.ll[this.languageId].no_device,
                  duration: 5, 
                }
                )
              }
            } 
          }).catch(() => {
          })
    },

    getUserFileTypeList(){
      getFileTypeList({}).then(ret => {
                if(ret.data.code == 200) {
                  console.info("ret.data",ret.data)
                  this.fileTypeList = ret.data.data
                  this.goto();
                }
              }).catch(() => {
              })
    },

  changeId(item){
    //支持上传文件格式
    this.fileTypeListId=item.dir
    this.dir = item.dir
    setDir(this.fileTypeListId)
    setCurrentDir(this.fileTypeListId)
    this.setFileTypesString();
    this.preDir=""
    this.goto()
    console.info("typetype",this.fileTypeListId)
  },
    handleFocus() {
      // window.scrollTo(0, 0); // 滚动到顶部

      this.isFocused = true;
    },
    handleBlur() {
      window.scrollTo(0, 0); // 滚动到顶部

      this.isFocused = false;
    },
    refreshLogins(){
      refreshLogin({"uri":""}).then(ret => {
            if(ret.data.code == 200) {
              setToken(ret.data.data.token)
            }
          }).catch(() => {
          })
    },
    onSelectChange (selectedRowKeys, selectedRows)  {
      //console.log(selectedRowKeys, selectedRows);
      this.selectedRowKeys = selectedRowKeys
      let names = [];
      for (let v of selectedRows){
        names.push(v.filename)
      }
      this.selectedNames = names;
    },
    customRowFunc(record,index){
      return {
        props: {},
        on: { // 事件
          click: () => {
            //console.log(event);
            if ((this.addFolder && index === 0) || (this.renameIndex !== -1 && this.renameIndex === index)){
              // 阻止事件 添加的目录和正在重命名的目录
              return
            }
            let selectedIdx = this.selectedRowKeys.indexOf(index)
            if ( selectedIdx === -1 ){
              this.selectedRowKeys.push(index)
              this.selectedNames.push(record.filename)
            }else{
              this.selectedRowKeys.splice(selectedIdx,1)
              this.selectedNames.splice(selectedIdx,1)
            }
            //console.log(selectedIdx ,this.selectedRowKeys );
            
          },       // 点击行
          dblclick: () => {},
          contextmenu: () => {},
          mouseenter: () => {},  // 鼠标移入行
          mouseleave: () => {}
        },

      };
    },
    goPreDir(){
      if (this.preDir=="" || this.preDir=="/" ) {
        this.preDir = ""
        return
      }
      let nowDir = this.getUpperDirectory(this.dir)
      if(!nowDir) {
        return;
      }
      this.dir = this.preDir
      setCurrentDir(this.dir)
      let preDir = this.getUpperDirectory(this.dir)
      this.preDir = preDir
      console.info("this.preDir11",this.preDir)
      if (this.preDir=="" || this.preDir=="/" ) {
        this.preDir = ""
      }
      console.info("this.preDir",this.preDir)
      this.goto()
    },
    goNextDir(item){
      if(item.type==2) {
        return
      }
      this.preDir = this.dir
      this.dir = item.id
      setCurrentDir(this.dir)
      console.info("ddddd",this.dir)
      this.goto()
    },
     getUpperDirectory(path) {
        // 确保路径以斜杠开头和结尾
        if (!path.startsWith('/') || !path.endsWith('/')) {
            console.error("路径格式错误，应以斜杠开头和结尾。");
            return null;
        }

        // 移除末尾的斜杠（如果路径长度大于1，即不是根目录）
        if (path.length > 1) {
            path = path.slice(0, -1);
        }

        // 找到上一级目录的结尾位置
        const lastSlashIndex = path.lastIndexOf('/');

        // 如果是顶级目录（如`/aa/`），则返回null
        if (lastSlashIndex <= 0) {
            return null;
        }

        // 获取上一级目录
        const upperDirectory = path.substring(0, lastSlashIndex + 1);

        return upperDirectory;
    },
    getList1(){
      list({
        folder:this.dir,
        name:this.fileName,
        pageNo:this.pagination.current,
        pageSize: this.pagination.pageSize
      }).then(res => {
      console.info("resres",res)
        this.addFolder = false;
        this.addFolderName = "";
        this.selectedRowKeys = []
        this.selectedNames = []
        this.selectedFruits = []
        if(res.data) {
          this.resData = res.data.data || [];
        } else {
          this.resData = []
        }
        console.info("resres",this.resData)
      })
    },
    onShowSizeChange(current, pageSize) {
        console.log(current, pageSize);
        this.pagination.current = current
        this.pagination.pageSize = pageSize;
        //改变完 重新渲染数据
        // this.getList()
      },

    goback(){
      if (this.navs.length > 1) {
        this.navs.pop()
        this.goto()
      }
    },
    gonext(dir){
      if (dir !== ""){
        this.navs.push(dir)
        const path = this.navs.join("/")
        this.goto(path)
      }
    },
    clear(){
      this.fileName = ""
      this.goto();
    },
    goto() {
    this.loading = true; // 开始加载
    const promise = this.getList();
    if (promise && typeof promise.finally === 'function') {
      promise.finally(() => {
        setTimeout(() => {
          this.loading = false; // 加载完成
        }, 300);
      });
    } else {
      // 如果 this.getList() 不是一个 Promise 或者不支持 finally 方法
      promise.then(() => {
        setTimeout(() => {
          this.loading = false; // 加载完成
        }, 300);
      }).catch(() => {
        setTimeout(() => {
          this.loading = false; // 加载完成
        }, 300);
      });
    }
  },
  async getList() {
    try {
      const response = await list({
        folder: this.dir,
        name: this.fileName,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      });
      console.info("resres", response);
      this.addFolder = false;
      this.addFolderName = "";
      this.selectedRowKeys = [];
      this.selectedNames = [];
      this.selectedFruits = [];
      this.resData = response.data.data || [];
      console.info("resres", this.resData);
    } catch (error) {
      console.error("Error fetching list:", error);
    }
  },
    progressColor (percent) {
      if (percent >= 80) {
        return 'red'
      } else if (percent >= 50) {
        return '#EAC100'
      }
    },
    openAddFolder(){
      if (!this.addFolder){
        const newRow = {
          filename:"",
          isDir:true,
          date:"",
          size:"-",
        }
        this.addFolder = true
        this.addFolderName = ""
        this.resData.items = [newRow,...this.resData.items]
        this.$nextTick(()=>{
          this.$refs.addFolderInputRef.focus()
        })
      }
    },
    handleAddFolderOK(){
      if (this.addFolder && this.addFolderName != ""){
        const path = this.navs.join("/") + "/" + this.addFolderName
        const args = { path:path}
        mkdir(args).then(() =>{
          const path = this.navs.join("/")
          this.goto(path)
        })
      }
    },
    handleAddFolderCancle(){
      if (this.addFolder){
        this.addFolder = false
        this.addFolderName = ""
        this.resData.items = this.resData.items.slice(1)
      }
    },
    handleUploadType(item){
      if (item.key === "folder"){
        this.uploadDirectory = true
      }else{
        this.uploadDirectory = false
      }
      this.uploadFileOpen = true
      this.$nextTick(() =>{
        this.$refs.upload.$refs.uploadRef.$el.firstChild.click();
        this.uploadFileOpen = false
      })
    },
    handleUploadType1(){
      //判断是否有下载中的操作
      for (var item of this.downloadProgressList) {
        if(item.status != 'success') {
          return this.$message.error("请等待下载完再导入")
        }
      }
      this.showDownloadList = false;


      this.uploadDirectory = false

      this.uploadFileOpen = true
      this.$nextTick(() =>{
        this.$refs.upload.$refs.uploadRef.$el.firstChild.click();
        this.uploadFileOpen = false
      })
    },

    handleDevice(key) {
      this.deviceId = key;
      for (let i = 0; i < this.deviceList.length; i++) { // 遍历行
        if(this.deviceList[i].machineNumber == this.deviceId) {
          this.deviceName = this.deviceList[i].modelName;
          setMachineModel(this.deviceName)
          setMachineNumber(this.deviceList[i].machineNumber)
          this.getUserFileTypeList()
        }
      }
      this.showType=1;
      this.uploadList = {}

      return;
      // this.goto(0);
    },
    handleLanguage(key) {

      this.languageId =key;
      console.info("this.languageId",this.languageId)
      const selectedLanguage = this.languageList.find(item => item.id == this.languageId);
      if (selectedLanguage) {
        this.languageName = selectedLanguage.name;
        setLanguage(selectedLanguage.id)
        console.info("getLanguage()",getLanguage())
        this.showType=1;
      }

      this.ll[this.languageId].timeList.forEach((tab) => {
        if(tab.id == this.timeId) {
          this.timeName = tab.name
        }
      });
    },


    handleBeforeUpload1(file){
      // console.log(file);
      // check

      const filename = file.name
      let path = this.navs.join("/")
      if (file.webkitRelativePath !== ""){
        const lastIndex = file.webkitRelativePath.lastIndexOf("/")
        if ( lastIndex > 0){
          path += "/" + file.webkitRelativePath.slice(0,lastIndex)
        }
      }
     
      // 分片大小 4M
      const sliceSize = 4 * 1024 *1024
      const size = file.size
      const total = Math.ceil(size / sliceSize)

      // md5
      this.getFileMd5(file,(fileMd5) => {
        const args = {path:path,filename:filename,md5:fileMd5,size:size,sliceTotal:total,sliceSize:sliceSize}
        uploadCheck(args).then(ret =>{
          this.showTransfer = true;
          this.showTransferUploadList = true;
          this.uploadList[filename] = {
            key:fileMd5,
            filename:filename,
            total:size,
            upSize:0
          }
          //console.log(ret);
          // 开始上传
          if (ret.need) {
            const token = ret.token
            for (let current = 0;current < total ;current++){
              const index = current.toString()
              if (ret.existSlice === null || !(index in ret.existSlice)){
                let start = sliceSize * current,
                    end = start + sliceSize > file.size ? file.size : start + sliceSize;
                const blob = file.slice(start,end)

                let fd = new FormData();
                fd.append('path',path);
                fd.append('file', blob);
                fd.append('filename', filename);
                fd.append('current', index);
                fd.append('token', token);

                uploadFile(fd).then(() => {
                  // console.log(index,"ok");
                  this.updateProgress(fileMd5, end-start)
                })
              }else{
                this.updateProgress(fileMd5,sliceSize)
              }
            }
          }else{
            this.updateProgress(fileMd5,size)
          }
        })

      })
      //console.log(filename,path,size,total);
      return false
    },
    handleBeforeUpload(file){
      const maxFileSize = 400 * 1024 * 1024;
      if (file.size > maxFileSize) {
        return this.$message.error(this.ll[this.languageId].max_size)
      }
      const filename = file.name
      // 分片大小 4M
      const size = file.size
        this.getFileMd5(file,async(fileMd5) => {
          this.showTransfer = true;
          this.showTransferUploadList = true;
          this.uploadList[filename] = {
            key:fileMd5,
            filename:filename,
            total:size,
            upSize:0,
            percent:0,
            status:"active"
          }
          console.info("this.uploadList111",this.uploadList)
          let hashs =[]
          hashs.push(fileMd5)
          let fd = new FormData();
          fd.append('file', file);
          fd.append('hash', JSON.stringify(hashs));
          fd.append('folder', this.dir);
          fd.append('source', 3);

          let params = {}
          params.hash = JSON.stringify(hashs)
          params.folder = this.dir
          params.source = 3

          let uri = "api/v1/file/uploadAll"
          let headers = getApiHeader(params,uri)
          headers.Authorization = "Bearer "+getToken()
          console.info("headers",headers)
          try {
          
            const response = await request.post('/api/v1/file/uploadAll', fd, {
              headers: headers,
              timeout: 0,
              onUploadProgress: progressEvent => {
                console.info("progressEvent.loaded",progressEvent.loaded,size,this.uploadList)
                this.updateProgress(filename, progressEvent.loaded);
              },
            });

            console.log('Upload response response:', response);

            // 检查响应状态和数据
            if (response.status === 200 && response.data && response.data.code === 200) {
              if(this.uploadList[filename].percent < 100) {
                console.info("reset 100",filename,size,this.uploadList[filename].percent)
                this.updateProgress(filename,size);
              }
              console.log('Upload success:', response);
              // 处理成功的逻辑
              this.getUserFileTypeList()
              this.goto()
            } else {
              console.error('Upload failed with response:', response);
              // 处理失败的逻辑
            }


            console.log('Upload success:', response);
          } catch (error) {
            console.error('Upload failed:', error);
          } finally {
            this.uploadProgress = null;
          }
          return;
      })
    },
    getFileMd5(file,callback){
      let fileSpark = new SparkMD5(),
          fileReader = new FileReader();

      fileReader.readAsBinaryString(file);
      fileReader.onload = function (ev) {
        fileSpark.appendBinary(ev.target.result);
        callback(fileSpark.end());
      };
      
    },
    updateProgress(key,value){
      const upInfo = this.uploadList[key]
      this.uploadList[key].upSize = value

      this.uploadList[key].percent = Math.round((value * 100) / upInfo.total);

      console.info("this.uploadList[key].percent",key,value,upInfo.total,this.uploadList[key].percent)
      // this.uploadList[key].status = "active"
      upInfo.upSize = value
      if (upInfo.percent >= 100 || (upInfo && upInfo.upSize >= upInfo.total)){
        this.uploadList[key].status = "success"
        this.uploadList[key].percent  = 100
      }

      // 页面数据强制刷新
      this.$forceUpdate();
    },
    uploadFinallyCount(){
      let cnt = 0
      for (let k in this.uploadList){
        if (this.uploadList[k].percent >= 100){
          cnt++
        }
      }
      return cnt
    },
    openRemoveModal(id){
      if (id == '' && this.selectedFruits.length==0) {
        return this.$message.error(this.ll[this.languageId].please_choose)
      }
      this.removeModalVisible = true
      this.deleteId = id
      this.resData.forEach((tab) => {
          if(tab.id==this.selectedFruits[0]) {
             this.deleteName = tab.name
          }
        });

      if(this.selectedFruits.length>1) {
       this.deleteName+=this.ll[this.languageId].so
      } 

    },
    handleRemove(){
      let args = {}
      if (this.deleteId != "") {
        let ids = []
        ids.push(this.deleteId)
        args = JSON.stringify(ids)
      } else {
        args = JSON.stringify(this.selectedFruits)
      }
      fileRemove({"id":args}).then(()=>{
        this.getUserFileTypeList()
        this.goto()
        this.deleteId = ""
      })
      .finally(()=>{
        this.removeModalVisible = false
        this.deleteId = ""
      })
    },
    openRename(){
      if (this.selectedRowKeys.length === 1){
        if (this.addFolder){
          this.handleAddFolderCancle()
          this.selectedRowKeys = [this.selectedRowKeys[0]-1]
        }
        this.renameIndex = this.selectedRowKeys[0]
        this.renameValue = this.selectedNames[0]
        this.$nextTick(()=>{
          this.$refs.renameInputRef.focus()
        })
      }
      
    },
    handleRenameOK(){
      let path = this.navs.join("/")
      rename({path:path,oldName:this.selectedNames[0],newName:this.renameValue})
      .then(()=>{
        this.goto(path)
      })
      .finally(() => {
        this.handleRenameCancle()
      })
    },
    handleRenameCancle(){
      this.renameIndex = -1
    },
    openMvcpModal(move){
      if (this.selectedNames.length == 0){
        return
      }
      
      if (move){
        this.mvcpMove = true
        this.dirModalTitle="移动到"
        this.dirModalOkText="移动到此"
      }else{
        this.mvcpMove = false
        this.dirModalTitle="复制到"
        this.dirModalOkText="复制到此"
      }
      
      this.mvcpSource = []
      const path = this.navs.join("/")
      for (let name of this.selectedNames){
        this.mvcpSource.push(path + "/" + name)
      }

      this.dirModalNavs = [this.root]
      this.mvcpGetList(this.dirModalNavs.join("/"))
      //console.log(this.mvcpSource,this.dirModalVisible);
      this.dirModalVisible = true
    },
    mvcpGetList(path){
      list({path:path}).then(res => {
        this.dirModalData = [];
        for (let v of res.items){
          if (v.isDir){
            this.dirModalData.push(v)
          }
        }
        
        this.dirModalData.sort((a,b) =>{
          if ( !a.isDir && b.isDir){
            return 1
          }else if (a.isDir == b.isDir){
            return a.filename - b.filename
          }
          return -1
        })
        //console.log(this.dirModalData);
      })
    },
    mvcpGoto(index){
      if (index >= 0 && index < this.dirModalNavs.length ){
        this.dirModalNavs.splice(index+1)
        const path = this.dirModalNavs.join("/")
        this.mvcpGetList(path)
      }
    },
    mvcpGoback(){
      if (this.dirModalNavs.length > 1) {
        this.dirModalNavs.pop()
        const path = this.dirModalNavs.join("/")
        this.mvcpGetList(path)
      }
    },
    mvcpGonext(dir){
      if (dir !== ""){
        this.dirModalNavs.push(dir)
        const path = this.dirModalNavs.join("/")
        this.mvcpGetList(path)
      }
    },
    handleMvcp(){
      const args = {move:this.mvcpMove,source:this.mvcpSource,target:this.dirModalNavs.join("/")}
      mvcp(args)
      .then(()=>{
        this.goto()
      })
      .finally(()=>{
        this.dirModalVisible = false
      })
    },
    showDownload(){
      if (this.selectedRowKeys.length > 0){
        for (var idx of this.selectedRowKeys){
          if (this.resData.items[idx].isDir){
            return false
          }
        }
        return true
      }
      return false
    },

    // handleDownload(){
    //       const args = {id:JSON.stringify(this.selectedFruits),format:"png"}
    //       downloadFile(args).then(res =>{
    //         console.info("res.data",res.data.data)
    //         for (var item of res.data.data){
    //           console.info("item",item)
    //           // const blob = new Blob(item.path);
    //           // var downloadElement = document.createElement("a");
    //           // var href = window.URL.createObjectURL(blob);
    //           // downloadElement.href = href;
    //           // downloadElement.download = decodeURIComponent(item.name);
    //           // document.body.appendChild(downloadElement);
    //           // downloadElement.click();
    //           // document.body.removeChild(downloadElement);
    //           // window.URL.revokeObjectURL(href); 
    //         }
    //       })
    // },
    openShareModal(){
      if (this.selectedNames.length === 0){
        return
      }
      this.shareModalVisible = true;
      this.shareCreate = true;
      this.shareTime = 7
      this.shareTitle = "分享文件(夹):"
      this.sharedCopied = false
      if (this.selectedNames.length === 1){
        this.shareTitle += this.selectedNames[0]
      }else{
        this.shareTitle += this.selectedNames[0] + "等"
      }
    },
    handleShareCreate(){
      const args = {path:this.navs.join("/"),filename:this.selectedNames,deadline:this.shareTime}
      sharedCreate(args)
      .then((ret)=>{
        this.shareCreate = false;
        this.sharedRoute = ret.route;
        this.sharedToken = ret.sharedToken;
        this.sharedDeadline = ret.deadline;
        this.sharedCopyText = makeSharedLink(this.sharedRoute, this.sharedToken)
      })
      
    },
    userLogout(){
      storage.remove("Access-Token");
      setMachineModel("")
      setMachineNumber("")
      setTimeout(() => {
        this.$router.push({ name: 'login' })
      }, 500)
    },
    gotoSharedList(){
      const listUrl = this.$router.resolve({
        name:"sharedlist"
      })
      window.open(listUrl.href,'_blank')
    }
  },

}
</script>

<style>
.custom-loading .el-loading-spinner .path {
  stroke: #275B52 !important;
}

.custom-loading .el-loading-spinner .circular {
  border-top-color: #275B52 !important;
  border-right-color: #275B52 !important;
  border-bottom-color: #275B52 !important;
  border-left-color: #275B52 !important;
}

.custom-loading .el-loading-text {
  color: #275B52 !important;
}

body{
 font-family: PingFangSC,PingFang SC;
 background:#fff;
}
.loading-spinner .ant-spin-dot {
  font-size: 20rem; 
}
.el-loading-spinner > div {
  border-color: #275B52 transparent transparent transparent;  /* 替换为你想要的颜色 */
}
.loading-spinner .ant-spin-dot-item {
  background-color: #275B52;
}
.ant-modal-header {
    padding: 16rem 24rem;
    padding-bottom: 30rem;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border-bottom: none;
    border-radius: 8rem 8rem 0 0;
}
.ant-modal-footer{
  border-top: none;
  margin-top:10rem;

}
.ant-spin-nested-loading > div > .ant-spin {
  /* margin-top:90rem; */
  padding-top: 600rem;
}
.ant-modal-body {
    padding: 0rem 24rem;
    font-size: 14rem;
    line-height: 1.5;
    word-wrap: break-word;
}
.canvas{
  width: 128rem;
  height: 128rem;
  border-radius: 8rem;
  /* border: 1rem solid #C6D5E1; */
}

.weixinurl{
  width: 305rem;
    /* height: 90rem; */
    background: rgb(239, 242, 238);
    border-radius: 8rem;
    color: rgb(0, 0, 0);
    margin-top: 40rem;
    /* position: absolute; */
    margin: auto;
    margin-top: 50rem;
    margin-bottom: 20rem;
    padding: 15rem;
    font-weight: 500;
}
.ant-progress-status-success .ant-progress-bg {
    background-color: #275B52;
}
.ant-progress-status-success .ant-progress-text {
    color: #275B52;
}
.ant-progress-text {
    display: inline-block;
    width: 2em;
    /* margin-left: 8rem; */
    color: rgba(0, 0, 0, 0.45);
    font-size: 1em;
    line-height: 1;
    white-space: nowrap;
    text-align: right;
    vertical-align: middle;
    word-break: normal;
}
.ant-progress-status-success .ant-progress-text {
    color: #275B52;
}

.radio-group {
  display: flex;
  flex-wrap: wrap;
  height: 100rem;
}

.radio-item {
  display: flex;
  align-items: center;
  margin-bottom: 8rem; /* 可选: 添加一些底部间距 */
  width:40%;
}
.custom-progress {
  width: 100%; /* 设置进度条宽度 */
}

.custom-progress .ant-progress-inner .ant-progress-bg {
  height: 4rem !important; /* 设置进度条高度 */
  border-radius: 2rem !important;
}

.ant-btn {
    line-height: 1.499;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1rem solid transparent;
    box-shadow: 0 2rem 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32rem;
    padding: 0 15rem;
    font-size: 14rem;
    border-radius: 2rem;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border-color: #d9d9d9;
    width: 80rem;
    height: 34rem;
}
.ant-btn:hover,.ant-btn:focus {
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border-color: #d9d9d9;
}
.ant-btn-primary {
    color: #fff;
    background-color: #000;
    border-color: #000;
    text-shadow: 0 -1rem 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2rem 0 rgba(0, 0, 0, 0.045);
    height: 34rem;
    width: 80rem;
    font-size: 12rem;
    border-radius: 2rem;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

/* 修改选中颜色 */
.ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-checked .ant-radio-inner {
    border-color: #000;
}

.ant-radio-checked .ant-radio-inner::after {
    background-color: #000;
}

/* 修改悬停颜色 */
.ant-radio:hover .ant-radio-inner {
    border-color: #000;
}

.ant-radio-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 18rem;
    height: 18rem;
    background-color: #fff;
    border-color: #d9d9d9;
    border-style: solid;
    border-width: 1rem;
    border-radius: 100rem;
    transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
    top: 2rem;
    left: 2rem;
    display: table;
    width: 12rem;
    height: 12rem;
    background-color: #275B52;
    border-top: 0;
    border-left: 0;
    border-radius: 8rem;
    transform: scale(0);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: ' ';
}

.ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 8rem;
    box-shadow: 0 4rem 12rem rgba(0, 0, 0, 0.15);
    pointer-events: auto;
}
.ant-modal-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16rem;
    line-height: 22rem;
    word-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 30rem;
}

.scroll-row {
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
    -ms-overflow-style: none;  /* 隐藏 IE 和 Edge 的滚动条 */
  scrollbar-width: none;  /* 隐藏 Firefox 的滚动条 */
  height: 55rem;
    line-height: 55rem;
    padding: 0 16rem;
}

.scroll-row::-webkit-scrollbar {
  display: none;  /* 隐藏 Chrome, Safari 和 Opera 的滚动条 */
}

.scroll-content {
  display: inline-flex;
  align-items: center;
  height: 30rem;
}

.scroll-item {
  display: inline-block;
  margin: 0 12rem;
  white-space: nowrap;
  font-size: 15rem;
  font-weight: 400;
color: #595959;
}
.scroll-item:first-child {
  margin-left: 0; /* 第一个 scroll-item 的 margin-left 为 0 */
}
.overlay2 {
  position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    /* width: calc(100% - 32rem); */
    height: 70rem;
    background-color: #fff;
    z-index: 1003;

    border-top: 1rem solid #E4E4E4;
    }
    .overlay3item {
      width: 100%;
      padding-bottom: 75rem;
      background-color: white;
      border-top-left-radius: 16rem;
      border-top-right-radius: 16rem;
      transition: transform 0.3s ease-in-out;
      bottom: 0;
    position: fixed;
    }
    .overlay3 {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      justify-content: flex-end;
      align-items: flex-end;
      z-index: 1005;
    }
    .overlay4item {
      width: 90%;
    background-color: white;
    transition: transform 0.3s ease-in-out;
    border-radius: 12rem;
    margin:auto;
    }
    .overlay4 {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      justify-content: flex-end;
      align-items: flex-end;
      z-index: 1005;
      display: flex;

    }
.overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: none; /* Initially hidden */
      justify-content: flex-end;
      align-items: flex-end;
      z-index: 1002;
    }
    .ant-progress-status-success .ant-progress-text {
    display:none;
}
    .bottom-sheet {
      width: 100%;
      padding-bottom: 60rem;
      background-color: white;
      border-top-left-radius: 16rem;
      border-top-right-radius: 16rem;
      transform: translateY(100%);
      transition: transform 0.3s ease-in-out;
    }

    .overlay.active {
      display: flex;
    }

    .bottom-sheet.active {
      transform: translateY(0);
    }
    .ant-input-lg {
   
    font-size: 14rem;
}
</style>
<style scoped>
body{
 background-color: #fff;
}

#header{
  height:60rem;
  line-height:60rem;
  margin-bottom:10rem;
}
.path{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:68rem;
}



#download_div {
  box-shadow: 0 1rem 20rem rgba(0,0,0,.2);
  border:2rem;
  border-radius:8rem;
  width:617rem;
  position: fixed;
  bottom: 25rem;
  right: 25rem;
  z-index: 1000;
  background: white;
  overflow: hidden;

  
}
.download_div_row {
  height:40rem;
  line-height:40rem;
  margin-bottom:10rem;
  display: flex;
}

/* 滚动条宽度 */
::-webkit-scrollbar {
 width: 7rem;
 height: 10rem;
}
/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
 background-color: #a1a3a9;
 border-radius: 3rem;
}

.new_header{
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  background: #fff;
}


.right_block{
  margin-left: 16rem;
  margin-right: 16rem;
  cursor: pointer;
  display: flex;justify-content: space-between;align-items: center;
  height: 50rem;
  font-size: 15rem;
}
.block_d{
  margin-left: 15rem;
}
.content{
  display: flex;
  justify-content: space-between;
  height: 100vh;
}
.c-left{
  width: 223rem;
  background-color: #EFF2EE;
}
.left-item{
  height: 68rem;
  width: 100%;
  padding-left: 25rem;
  padding-right: 25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  
}
.left-item-on {
  /* background-color: #C9CFC5; */
  font-weight: bold;
  color:#000;
  position: relative;
  display: flex;
    justify-content: center;
}
.left-item-on-line{
  position: absolute;
    width: 70%;
    height: 2rem;
    bottom: 5px;
    background: #000;
    margin: auto;

}


.left-item-l{
  color: #23272A;
  font-size: 16rem;
  font-weight: 600;
  display: flex;
    align-items: center;
}
.left-item-r{
  color:#23272A;
  font-size: 15rem;
  margin-right: 10rem;
}
.c-right{
  /* margin-right: 25rem; */
  background: #fff;
  /* margin-left:200rem; */
  /* margin-top:88rem;
  min-width: auto;
  overflow: hidden; */
}
.border_right {
        position: relative;
        margin-right: 15rem;
    }
.border_right::after {
  content: '';
    position: absolute;
    top: 20%;
    right: -15rem;
    width: 2rem;
    height: 60%;
    background-color: #D1D6DE;
    }
    .search-icon {
  cursor: pointer;
  color: #979BA2;
  width:16rem;height:16rem;
}

.custom-input {
height: 34rem;
background: #F9FAF9;
border-radius: 17rem;
border: 1rem solid #DBDBDB;
}
.custom-input:hover {

  box-shadow: none;
}
.custom-input:focus {
  background-color: #fff;
  /* border-color: #E0E5DE; */
  box-shadow: none;
}

.border_right_d {
        position: relative;
    }


    .vertical-center-modal {
  text-align: center;
  white-space: nowrap;
}



/* use css to set position of modal */
.vertical-center-modal {
  text-align: center;
  white-space: nowrap;
}

.vertical-center-modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.vertical-center-modal .ant-modal {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
}

/*
// Use flex which not working in IE
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center-modal .ant-modal {
  top: 0;
}
*/

.loading-indicator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.loading-spinner{
  /* height: 200rem; */
  /* top:90rem; */
  /* height: calc(100vh -160rem); */
  /* height: 1000rem; */
 /* 为头部留出空间 */
  /* width: calc(100% - 200rem); */
  /* height: calc(100vh - 88rem); */
  /* overflow: hidden; */
  /* width:100%; */
  color:#275B52;


}
.loading-spinner .ant-spin-nested-loading,
.loading-spinner .ant-spin-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}




#sidebar {
  width: 200rem;
  position: fixed;
  top: 88rem; /* 保持在头部下面 */
  bottom: 0;
  background: #f4f4f4;
  overflow-y: auto;
}

.content_wrapper {
  margin-top: 150rem; /* 为头部留出空间 */
  width: calc(100% - 200rem);
  /* height: calc(100vh - 88rem); */
  /* overflow: hidden; */
  width:100%;
}

.content_content {
  height: 100%;
  /* overflow-y: auto; */
  padding-right: 0;
  padding-left: 0;
  margin-top: 90rem; /* 为头部留出空间 */
}


</style>
